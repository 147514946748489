import { useState, useContext, useEffect } from 'react'
import { ClientContext } from '../../../../contexts/clientProvider'
import { getDadosComercial, getComponentMap } from '../../../General/Format/cliente';


const Comercial = ({ client, setClient, handleFecharClick }) => {
    const { updateCliente } = useContext(ClientContext);
    const [type, setType] = useState('Geral');
    const [editedData, setEditedData] = useState({});
    const [dados, setDados] = useState([]);

    useEffect(() => {
        setEditedData(client);
    }, []);

    useEffect(() => {
        const newDados = getDadosComercial(editedData);
        setDados(newDados);
    },[editedData]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditedData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleEditClick = () => {
        setType('Edit');
        setEditedData(client);
    };

    const handleSaveClick = async () => {
        const newClient = await updateCliente(editedData);
        setClient(newClient);
        setType('Geral');
        handleFecharClick();
    };

    const handleCancelClick = () => {
        setType('Geral');
    };


    const componentMap = getComponentMap(
        handleFecharClick,
        handleCancelClick,
        handleSaveClick,
        handleEditClick,
        handleInputChange,
        type
    );

    return (
        <>
            <div className='visualizar-modal-content'>

                {dados.map((dado, index) => (
                    <div className='visualizar-modal-content-item' key={index}>{componentMap[type](dado)}</div>
                ))}

            </div>
            <div className='visualizar-edit-box'>
                {componentMap[`buttons${type}`]()}
            </div>
        </>
    )
}

export default Comercial