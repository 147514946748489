import React, { useEffect, useState } from 'react';
import { TbTriangleFilled, TbTriangleInvertedFilled } from "react-icons/tb";
import DataTable from '../../../Table';


const DetailsModal = ({ closeModal, client, month, ano }) => {
    const [headers, setHeaders] = useState([
        { key: 'etiqueta', label: 'Patrimônio', },
        { key: 'nome_anexo_atual', label: 'Anexo Atual', },
        { key: 'nome_anexo_anterior', label: 'Anexo Anterior', },
        { key: 'usuario', label: 'Usuário', },
        { key: 'marca', label: 'Marca', },
        { key: 'modelo', label: 'Modelo', },
        { key: 'ramal', label: 'Ramal', },
    ]);

    return (
        <div className="overlay" onClick={e => {
            if (e.target === e.currentTarget) closeModal();
        }}>
            <div className="modal">
                <div className="header" >
                    <div ></div>
                    <h2>Relatorio do Cliente</h2>
                    <button className="closeButton" onClick={closeModal} style={{ fontSize: "45px" }}>
                        &times;
                    </button>
                </div>
                <div className="body">
                    <h2> {client.nome}</h2>

                </div>
                <DataTable data={client.moves} headers={headers} itemsPerPage={10} exportButton={true}
                    docTitle={`Detalhes de Movimentação - ${client?.nome || "Cliente"}`}
                    hasMonthSelection={`${month}/${ano}`} />

            </div>
        </div>
    );
};

export default DetailsModal;