import { useState, useContext, useEffect } from 'react'
import { FornecedoresContext } from '../../../contexts/fornecedoresProvider'
import { UserContext } from '../../../contexts/userProvider'
import DataTable from '../../Table'
import LoadingSpinner from '../../Loader/load'
import Visualizar from '../Visualizar'
import '../styles.css'

const Consulta = () => {
    const { fornecedores, deleteFornecedor } = useContext(FornecedoresContext)
    const { setAlert } = useContext(UserContext)
    const [fornecedoresList, setFornecedoresList] = useState([])
    const [fornecedorSelecionado, setFornecedorSelecionado] = useState(null)

    useEffect(() => {
        let newFornecedoresList = fornecedores ? fornecedores : []
        setFornecedoresList(newFornecedoresList)
    }, [fornecedores])

    const visualizar = (fornecedor) => {
        const newFornecedor = fornecedoresList.find(f => f.id === fornecedor)
        setFornecedorSelecionado(newFornecedor)
    }

    const deletar = (fornecedor) => {
        setAlert({
            type: 'confirm',
            placeholder: 'Deseja realmente deletar a linha?',
            visible: true,
            confirm: true,
            title: 'Atenção!',
            callback: () => deleteFornecedor(fornecedor),
            params: fornecedor
        })

    }

    const headers = [
        { label: "Nome do Fornecedor", key: "nome", sortable: true },
        { label: "CNPJ", key: "cpf_cnpj", sortable: false },
        { label: "Telefone", key: "telefone", sortable: false },
        { label: "Ações", key: "actions", sortable: false, actions: { "visualizar": visualizar, "deletar": deletar } }
    ]

    const closeModal = () => {
        setFornecedorSelecionado(null)
    }

    return (
        <div className='fornecedores-consulta-contander'>

            {fornecedoresList.length > 0 ? (<DataTable headers={headers} data={fornecedoresList} itemsPerPage={5} />) : (<LoadingSpinner />)}

            {fornecedorSelecionado ? (<Visualizar fornecedor={fornecedorSelecionado} setFornecedor={setFornecedorSelecionado} closeModal={closeModal} />) : null}

        </div>
    )
}

export default Consulta
