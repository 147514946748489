import React, { useEffect, useState } from 'react'
import LoadingSpinner from '../../Loader/load';
import Geral from './Types/geral';
import Financeiro from './Types/financeiro';
import Comercial from './Types/comercial';
import Tecnica from './Types/tecnica';
import Patrimonios from './Types/patrimonios';
import Itens from './Types/itens';
import { BiX } from "react-icons/bi";
import './styles.css'

const Visualizar = (props) => {
    const [client, setClient] = useState(null);
    const [type, setType] = useState('geral');
    const { cliente, closeModal } = props;

    useEffect(() => {
        let newCliente = cliente ? cliente : {};
        setClient({ ...newCliente });
    }, [cliente])

    const handleFecharClick = () => {
        closeModal();
        setClient(null);
    }
    const clientType = {
        geral: <Geral client={client} setClient={setClient} handleFecharClick={handleFecharClick} />,
        financeiro: <Financeiro client={client} setClient={setClient} handleFecharClick={handleFecharClick} />,
        comercial: <Comercial client={client} setClient={setClient} handleFecharClick={handleFecharClick} />,
        'técnica': <Tecnica client={client} setClient={setClient} handleFecharClick={handleFecharClick} />,
        'patrimônios': <Patrimonios client={client} setClient={setClient} handleFecharClick={handleFecharClick} />,
        itens: <Itens client={client} setClient={setClient} handleFecharClick={handleFecharClick} />
    }


    return (
        <div className='visualizar-modal'>
            <div className='visualizar-modal-body'>
                <BiX onClick={() => closeModal()} className='fechar-top-button' />
                <div className='visualizar-modal-header'>
                    <h1>Dados do Cliente</h1>
                    <div className="visualizar-tabs">
                        {Object.keys(clientType).map((key) => (
                            <button
                                key={key}
                                className="visualizar-tab-button"
                                onClick={() => setType(key)}
                                style={
                                    type === key
                                        ? { borderBottom: "1px solid #9ac31c", color: "#9ac31c" }
                                        : null
                                }
                            >
                                {key.split("_").map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(" ")}
                            </button>
                        ))}
                    </div>
                </div>
                {!client ? <LoadingSpinner /> : clientType[type]}
            </div>
        </div>
    )
}

export default Visualizar
