import { createContext, useEffect, useState, useContext } from "react";
import { UserContext } from "./userProvider";
import apiUser from "../apiUser";

export const ClientContext = createContext();

export const ClientProvider = ({ children }) => {
  const { signed, setAlert } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [clientes, setClientes] = useState(null);
  const [idCliente, setIdCliente] = useState(null);
  const estados = [
    { id: '12', nome: 'AC' },
    { id: '27', nome: 'AL' },
    { id: '16', nome: 'AP' },
    { id: '13', nome: 'AM' },
    { id: '29', nome: 'BA' },
    { id: '23', nome: 'CE' },
    { id: '53', nome: 'DF' },
    { id: '32', nome: 'ES' },
    { id: '52', nome: 'GO' },
    { id: '21', nome: 'MA' },
    { id: '51', nome: 'MT' },
    { id: '50', nome: 'MS' },
    { id: '31', nome: 'MG' },
    { id: '41', nome: 'PA' },
    { id: '25', nome: 'PB' },
    { id: '41', nome: 'PR' },
    { id: '26', nome: 'PE' },
    { id: '22', nome: 'PI' },
    { id: '33', nome: 'RJ' },
    { id: '24', nome: 'RN' },
    { id: '43', nome: 'RS' },
    { id: '11', nome: 'RO' },
    { id: '14', nome: 'RR' },
    { id: '42', nome: 'SC' },
    { id: '35', nome: 'SP' },
    { id: '28', nome: 'SE' },
    { id: '17', nome: 'TO' },
  ];


  const getClientes = async () => {
    setLoading(true);
    try {
      const response = await apiUser.get("/clientes/dados");
      const { success, dados, message } = response.data;
      if (success) {
        setClientes(dados);
        setLoading(false);
      } else {
        setAlert({
          visible: true,
          title: "Atenção!",
          placeholder: message,
          confirm: false,
          type: "warning",
        });
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (signed && !clientes) getClientes();
  }, []);


  const verifyMovimentsByCliente = async (id, mes) => {
    try {
      const response = await apiUser.post("/movimenta/byClient", { id_cliente: id, mes });
      const { success, data, message } = response.data;
      if (success) {
        setLoading(false);
        return data;
      } else {
        setAlert({
          visible: true,
          title: "Atenção!",
          placeholder: message,
          confirm: false,
          type: "warning",
        });
      }
    } catch (error) {
      console.log(error);
    }
  }


  const getPatsDetails = async (id_pat, id_patNFS) => {
    try {
      const response = await apiUser.post("/movimenta/byPatrimonios", { id_pat, id_patNFS });
      const { success, data, message } = response.data;
      if (success) {
        setLoading(false);
        return data;
      } else {
        setAlert({
          visible: true,
          title: "Atenção!",
          placeholder: message,
          confirm: false,
          type: "warning",
        });
        return [];
      }
    } catch (error) {
      console.log(error);
      return [];
    }
  }

  const verifyMovimentsByMonth = async (mes) => {
    try {
      const response = await apiUser.post("/movimenta/byMonth", { mes });
      const { success, data, message } = response.data;
      if (success) {
        setLoading(false);
        return data;
      } else {
        setAlert({
          visible: true,
          title: "Atenção!",
          placeholder: message,
          confirm: false,
          type: "warning",
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  const getPatrimoniosByCliente = async (id) => {
    try {
      const response = await apiUser.post("/clientes/patrimonios", { id });
      const { success, dados, message } = response.data;
      if (success) {
        setLoading(false);
        return dados;
      } else {
        setAlert({
          visible: true,
          title: "Atenção!",
          placeholder: message,
          confirm: false,
          type: "warning",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateCliente = async (data) => {
    try {
      const response = await apiUser.post("/clientes/update", {
        cliente: data,
      });
      const { success, message, dados } = response.data;
      if (success) {
        setAlert({
          visible: true,
          title: "Sucesso!",
          placeholder: message,
          confirm: false,
          type: "success",
        });
        getClientes();
        return dados;
      } else {
        setAlert({
          visible: true,
          title: "Atenção!",
          placeholder: message,
          confirm: false,
          type: "warning",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const createCliente = async (data) => {
    try {
      const response = await apiUser.post("/clientes/create", {
        cliente: data,
      });
      const { success, message, dados } = response.data;
      if (success) {
        setAlert({
          visible: true,
          title: "Sucesso!",
          placeholder: message,
          confirm: false,
          type: "success",
        });
        getClientes();
        return dados;
      } else {
        setAlert({
          visible: true,
          title: "Atenção!",
          placeholder: message,
          confirm: false,
          type: "warning",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteCliente = async (id) => {
    try {
      const response = await apiUser.post("/clientes/delete", { id });
      const { success, message } = response.data;
      if (success) {
        setAlert({
          visible: true,
          title: "Sucesso!",
          placeholder: message,
          confirm: false,
          type: "success",
        });
        getClientes();
        return true;
      } else {
        setAlert({
          visible: true,
          title: "Atenção!",
          placeholder: message,
          confirm: false,
          type: "warning",
        });
        return false;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAllServices = async () => {
    try {
      const response = await apiUser.get("/clientes/servicos");
      const { success, dados, message } = response.data;
      if (success) {
        return dados;
      } else {
        setAlert({ visible: true, title: "Atenção!", placeholder: message, confirm: false, type: "warning" });
      }
    } catch (error) {
      console.log(error);
    }
  };


  const getServicosPrestados = async (id) => {
    try {
      const response = await apiUser.post("/clientes/servicosPrestados", {
        id,
      });
      const { success, dados, message } = response.data;
      if (success) {
        return dados;
      } else {
        setAlert({
          visible: true,
          title: "Atenção!",
          placeholder: message,
          confirm: false,
          type: "warning",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const vincularServico = async (add, remove) => {
    try {
      const response = await apiUser.post("/clientes/vincularServicosPrestados", {
        add,
        remove,
      });
      const { success, message } = response.data;
      if (success) {
        setAlert({
          visible: true,
          title: "Sucesso!",
          placeholder: message,
          confirm: false,
          type: "success",
        });
        return true;
      } else {
        setAlert({
          visible: true,
          title: "Atenção!",
          placeholder: message,
          confirm: false,
          type: "warning",
        });
        return false;
      }
    } catch (error) {
      console.log(error);
    }
  }

  const createServico = async (data) => {
    try {
      const response = await apiUser.post("/clientes/createServico", {
        servico: data,
      });
      const { success, message, dados } = response.data;
      if (success) {
        setAlert({
          visible: true,
          title: "Sucesso!",
          placeholder: message,
          confirm: false,
          type: "success",
        });
        return dados;
      } else {
        setAlert({
          visible: true,
          title: "Atenção!",
          placeholder: message,
          confirm: false,
          type: "warning",
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  const updateServico = async (id, servico) => {
    try {
      const response = await apiUser.post("/clientes/updateServico", {
        id,
        servico,
      });
      const { success, message, dados } = response.data;
      if (success) {
        setAlert({
          visible: true,
          title: "Sucesso!",
          placeholder: message,
          confirm: false,
          type: "success",
        });
        return dados;
      } else {
        setAlert({
          visible: true,
          title: "Atenção!",
          placeholder: message,
          confirm: false,
          type: "warning",
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  const deleteServico = async (id) => {
    try {
      const response = await apiUser.post("/clientes/deleteServico", {
        id,
      });
      const { success, message } = response.data;
      if (success) {
        setAlert({
          visible: true,
          title: "Sucesso!",
          placeholder: message,
          confirm: false,
          type: "success",
        });
        return true;
      } else {
        setAlert({
          visible: true,
          title: "Atenção!",
          placeholder: message,
          confirm: false,
          type: "warning",
        });
        return false;
      }
    } catch (error) {
      console.log(error);
    }
  }



  return (
    <ClientContext.Provider value={{
      clientes,
      loading,
      setLoading,
      alert,
      setAlert,
      getPatrimoniosByCliente,
      updateCliente,
      estados,
      createCliente,
      deleteCliente,
      getAllServices,
      getServicosPrestados,
      vincularServico,
      createServico,
      updateServico,
      deleteServico,
      idCliente,
      setIdCliente,
      verifyMovimentsByCliente,
      verifyMovimentsByMonth,
      getPatsDetails
    }}>
      {children}
    </ClientContext.Provider>
  );
};
