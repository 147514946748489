import { useState, useEffect, useContext } from 'react'
import { PatrimonioContext } from '../../../../contexts/patrimonioProvider'
import DataTable from '../../../Table';
import EditPatrimonioModal from './editModal';
import Movimenta from '../../Movimenta';
import { BiX } from "react-icons/bi";

const PatrimonioModal = ({ handleCloseModal, patrimonioType }) => {
    const { patrimonios } = useContext(PatrimonioContext);
    const [newPatrimonios, setNewPatrimonios] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [editModal, setEditModal] = useState(false);
    const [patrimonioSelecionado, setPatrimonioSelecionado] = useState({});
    const [movimentaModal, setMovimentaModal] = useState(false);
    const [selectedPatrimonios, setSelectedPatrimonios] = useState([]);

    useEffect(() => {
        let nPatrimonios = patrimonios ? [...patrimonios] : [];
        setNewPatrimonios(nPatrimonios);
    }, [patrimonios]);

    const editarPatrimonio = (id) => {
        setPatrimonioSelecionado(id);
        setEditModal(true);
    }

    const headers = [
        { label: "Nº Patrimônio", key: patrimonioType === 'novo' ? 'netiqueta' : 'nEtiqueta', sortable: false },
        { label: "Equipamento", key: patrimonioType === 'novo' ? "produto" : 'equipamento', sortable: false },
        { label: "Nº Mac", key: patrimonioType === 'novo' ? 'nmac' : 'nMac', sortable: false },
        { label: "Nº Série", key: patrimonioType === 'novo' ? 'nserie' : 'nSerie', sortable: false },
        { label: "Cliente", key: "nome_cliente", sortable: false },
        { label: "Anexo", key: "nome_anexo", sortable: false },
        { label: "ID", key: "id", sortable: false },
        { label: "Ações", key: "actions", sortable: false, actions: { "editar": editarPatrimonio } },
    ]

    const handleSelectionChange = (newSelectedItems) => {
        setSelectedItems(newSelectedItems);
    };

    const handleMovimentaModal = () => {
        let selectedPatrimonios = newPatrimonios.filter(patrimonio => selectedItems.includes(patrimonio.id));
        setSelectedPatrimonios(selectedPatrimonios);
        setMovimentaModal(true);
    }

    return (
        <div className='visualizar-modal'>
            <div className='visualizar-modal-body' style={{ width: "90%" }}>
                <BiX onClick={handleCloseModal} className='fechar-top-button' />
                <div className='visualizar-modal-header'>
                    {selectedItems.length > 0 ? <div className='visualizar-modal-header'>
                        <h1 style={{ fontSize: '40px' }}>Total de patrimônios selecionados : {selectedItems.length}</h1>
                    </div> : <h1 style={{ fontSize: '20px' }}>Total de patrimônios consultados : {newPatrimonios.length}</h1>}
                </div>
                <div style={{ maxWidth: '95%' }}>
                    {newPatrimonios && <DataTable headers={headers} data={newPatrimonios} itemsPerPage={5} checkboxColumn={true} onSelectionChange={handleSelectionChange} />}
                </div>
                {editModal && <EditPatrimonioModal setEditModal={setEditModal}
                    patrimonioSelecionado={patrimonioSelecionado}
                    setPatrimonioSelecionado={setPatrimonioSelecionado}
                    patrimonioType={patrimonioType}
                    patrimonios={newPatrimonios}
                    />}
                {movimentaModal && <Movimenta selectedPatrimonios={selectedPatrimonios} setMovimentaModal={setMovimentaModal} setNewPatrimonios={setNewPatrimonios} newPatrimonios={newPatrimonios} setSelectedPatrimonios={setSelectedPatrimonios}/>}
                <div className="box-buttons" style={{ display: 'flex', justifyContent: 'center', width: "100%", gap: "20px" }}>
                    <button className='patrimonio-cadastro-button' onClick={() => handleCloseModal()}>Fechar</button>
                    {selectedItems.length > 0 && <button className='patrimonio-cadastro-button' onClick={() => handleMovimentaModal()}>Movimentar</button>}
                </div>
            </div>
        </div>
    )
}

export default PatrimonioModal
