import React, { useState } from 'react'
import Consulta from './Types/consulta'
import Cadastro from './Types/cadastro'
import './styles.css'

const Clientes = () => {
  const [type, setType] = useState('consulta')
  const [clientType, setClientType] = useState({
    consulta: <Consulta />,
    cadastro: <Cadastro />,
  })

  return (
    <div className='client-container'>
      <div className='client-header'>
        <button
          className="client-button"
          onClick={() => setType('consulta')}
          style={
            type === "consulta"
              ? { borderBottom: "1px solid #9ac31c", color: "#9ac31c" }
              : null
          }
        >
          Consulta
        </button>
        <button
          className="client-button"
          onClick={() => setType('cadastro')}
          style={
            type === "cadastro"
              ? { borderBottom: "1px solid #9ac31c", color: "#9ac31c" }
              : null
          }
        >
          Cadastro
        </button>
      </div>
      <div className='client-body'>{clientType[type]}</div>
    </div>
  )
}

export default Clientes;
