import { useState, useContext } from "react";
import { getDados, getComponentMap } from "../../General/Format/servicos";
import { ClientContext } from "../../../contexts/clientProvider";
import { UserContext } from "../../../contexts/userProvider";

const Cadastro = () => {
  const { createServico } = useContext(ClientContext);
  const { user, setAlert } = useContext(UserContext);
  const [servico, setServico] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setServico({ ...servico, [name]: value });
  };

  const dados = getDados(servico);
  const componentMap = getComponentMap(handleInputChange);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let newService = { ...servico };
    newService.created_at = newService.updated_at = new Date()
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");
    newService.id_usuario = user.id;
    newService.usuario = user.nome;
    if (!newService.nome) {
        setAlert({
            type: 'warning',
            placeholder: 'Nome do serviço é obrigatório',
            visible: true,
            confirm: false,
            title: 'Atenção!'
          })
      return;
    }
    try {
      const response = await createServico(newService);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="servico-form">
      {dados.map((dado, index) => (
        <div className="servico-form-item" key={index}>
          {componentMap["Geral"](dado)}
        </div>
      ))}
      <button className="servico-form-button" onClick={handleSubmit}>
        Salvar
      </button>
    </div>
  );
};

export default Cadastro;
