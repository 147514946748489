import { useState, useContext, useEffect } from 'react'
import { AnexosContext } from '../../../contexts/anexosProvider';
import { getDadosAnexo, getComponentMap } from '../../General/Format/anexo'
import { BiX } from "react-icons/bi";

const Visualizar = ({ anexo, setAnexo, closeModal }) => {
    const { updateAnexo } = useContext(AnexosContext);
    const [type, setType] = useState('Geral');
    const [editedData, setEditedData] = useState({});

    useEffect(() => {
        setEditedData(anexo);
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditedData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleEditClick = () => {
        setType('Edit');
        setEditedData(anexo);
    };

    const handleSaveClick = async () => {
        let editedDataCopy = { ...editedData };
        delete editedDataCopy.nome_operadora
        let newAnexo = await updateAnexo(editedDataCopy);
        setAnexo(newAnexo);
        setType('Geral');
    };

    const handleCancelClick = () => {
        setType('Geral');
    };

    const dados = getDadosAnexo(editedData);
    const componentMap = getComponentMap(
        closeModal,
        handleCancelClick,
        handleSaveClick,
        handleEditClick,
        handleInputChange,
    );
    return (
        <div className='visualizar-modal'>
            <div className='visualizar-modal-body'>
                <BiX className='fechar-top-button' onClick={() => closeModal()} />
                <div className='visualizar-modal-header'>
                    <h1>Anexo {anexo.nome}</h1>
                </div>
                <div className='visualizar-modal-content'>
                    {dados.map((dado, index) => (
                        <div className='visualizar-modal-content-item' key={index}>
                            {componentMap[type](dado)}
                        </div>
                    )
                    )}
                </div>
                <div className='visualizar-edit-box'>
                    {componentMap[`buttons${type}`]()}
                </div>
            </div>
        </div>
    )
}

export default Visualizar
