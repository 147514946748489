import React, { useState, useEffect } from 'react'
import Action from './Actions';
import RenderPageNumbers from './renderPageNumbers';
import PrintToPDF from './printToPDF';
import PrintToXLS from './printToXLS';
import { FaEdit } from 'react-icons/fa';
import { FaSave } from 'react-icons/fa';
import './styles.css';

const DataTable = ({ data, headers, checkboxColumn, onSelectionChange, exportButton, docTitle, noSort, hasMonthSelection, footerValues, fontHeader, fontData }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');
  const [filteredAndSortedData, setFilteredAndSortedData] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [itemsPerPage, setItemsperPage] = useState(5);
  const [editIndex, setEditIndex] = useState(null);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const handleSort = () => {
    setSortOrder((prevSortOrder) => (prevSortOrder === 'asc' ? 'desc' : 'asc'));
  };
  const currentData = filteredAndSortedData.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil((filteredAndSortedData.length || 0) / itemsPerPage);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchTerm, sortOrder]);

  useEffect(() => {
    const filteredData = data?.filter((item) => {
      return headers.some((header) => {
        const value = item[header.key];
        return (
          typeof value === 'string' &&
          value.toLowerCase().includes(searchTerm.toLowerCase())
        );
      });
    });
    const sortedData = [...filteredData]?.sort((a, b) => {
      const aValue = (a[headers[0]?.key] || 'nenhum dado cadastrado').toLowerCase();
      const bValue = (b[headers[0]?.key] || 'nenhum dado cadastrado').toLowerCase();

      return sortOrder === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
    });
    if (noSort) {
      setFilteredAndSortedData(JSON.parse(JSON.stringify(filteredData)));
      return;
    }
    else setFilteredAndSortedData(JSON.parse(JSON.stringify(sortedData)));
  }, [data, searchTerm, sortOrder, headers]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleCheckboxChange = (itemId) => {
    onSelectionChange((prevSelectedItems) => {
      if (prevSelectedItems.includes(itemId)) {
        setSelectedItems((prevSelectedItems) => prevSelectedItems.filter((id) => id !== itemId));
        return prevSelectedItems.filter((id) => id !== itemId);
      } else {
        setSelectedItems((prevSelectedItems) => [...prevSelectedItems, itemId]);
        return [...prevSelectedItems, itemId];
      }
    });
  };

  const handleSelectAll = () => {
    onSelectionChange((prevSelectedItems) => {
      if (prevSelectedItems.length === data.length) {
        setSelectedItems([]);
        return [];
      } else {
        setSelectedItems(data?.map((item) => item.id));
        return data?.map((item) => item.id);
      }
    });
  };

  const showItem = (item, header) => {
    if (header.type === 'money') return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item);
    return item;
  }

  const getHeaders = () => {
    let hds = [...headers];
    for (let i = 0; i < hds.length; i++) {
      if (hds[i].actions) {
        delete hds[i];
      }
    }
    return hds;
  }
  return (
    <>

      <div className="search-container" style={{ width: "100%" }}>
        <input
          type="text"
          placeholder="Pesquisar"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      <div className='box-items'>
        <span>Total de itens: {filteredAndSortedData.length}</span>

        <div className="items-per-page">

          <label htmlFor="items-per-page">Itens por página:</label>
          <select
            id="items-per-page"
            value={itemsPerPage}
            onChange={(e) => setItemsperPage(Number(e.target.value))}
          >
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
            <option value={filteredAndSortedData.length}>Todos</option>
          </select>
        </div>
      </div>
      <div className="main-table-container" >
        <table className="data-table" >
          <thead>
            <tr>
              {checkboxColumn && (
                <th>
                  <input
                    type="checkbox"
                    checked={selectedItems.length === data.length}
                    onChange={handleSelectAll}
                  />
                </th>
              )}
              {headers?.map((header, index) => (
                <th key={index}>
                  {header.label}{' '}
                  {header.sortable && (
                    <button className="sort-table-button" onClick={handleSort}>
                      {sortOrder === 'asc' ? '▲' : '▼'}
                    </button>
                  )}
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            {[...currentData]?.map((item, indexData) => (
              <tr key={item.id || item._id}>
                {checkboxColumn && (
                  <td>
                    <input
                      type="checkbox"
                      checked={selectedItems.includes(item.id)}
                      onChange={() => handleCheckboxChange(item.id)}
                    />
                  </td>
                )}
                {headers?.map((header, index) => (
                  <td key={index}>
                    {header.actions ? (
                      <>
                        {Object.entries(header.actions)?.map(([key, value]) => (
                          <Action key={key} type={key} action={() => value(item.id || item._id)} />
                        ))}
                      </>
                    ) :
                      header.editable ? (
                        editIndex === indexData ?
                          <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                            <input
                              type="text"
                              value={item[header.key] || ''}
                              onChange={(e) => header.editable(e, item, header.key)}
                              style={{ padding: '5px', borderRadius: '4px', border: '1px solid #ccc' }}
                            />
                            <button
                              onClick={() => header.saveEdit()}
                              style={{
                                backgroundColor: '#4CAF50',
                                color: 'white',
                                border: 'none',
                                borderRadius: '4px',
                                padding: '5px 10px',
                                cursor: 'pointer',
                              }}
                            >
                              <FaSave />
                            </button>
                          </div>
                          : (
                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                              {item[header.key]}
                              <button
                                onClick={() => {
                                  setEditIndex(indexData);
                                }}
                                style={{
                                  backgroundColor: '#4CAF50',
                                  color: 'white',
                                  border: 'none',
                                  borderRadius: '4px',
                                  padding: '5px 10px',
                                  cursor: 'pointer',
                                }}
                              >
                                <FaEdit />
                              </button>
                            </div>
                          )
                      ) :
                        (
                          showItem(item[header.key], header)
                        )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="pagination">
        <button
          onClick={() => setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))}
          disabled={currentPage === 1}
        >
          Anterior
        </button>

        <RenderPageNumbers handlePageChange={handlePageChange} totalPages={totalPages} currentPage={currentPage} />

        <button
          onClick={() => setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages))}
          disabled={currentPage === totalPages}
        >
          Próximo
        </button>
      </div>      {exportButton && (
        <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
          <PrintToPDF
            headers={getHeaders()} data={filteredAndSortedData} docTitle={docTitle} hasMonth={hasMonthSelection}
            footerValues={footerValues} fontHeader={fontHeader} fontData={fontData} />
          <PrintToXLS headers={headers} data={filteredAndSortedData} docTitle={docTitle} hasMonth={hasMonthSelection} footerValues={footerValues} />
        </div>
      )}

    </>
  );
};


export default DataTable;
