import { useState, useEffect, useContext } from "react";
import { GetDadosPatrimonio, getComponentMap } from "../../General/Format/patrimonio";
import { AnexosContext } from "../../../contexts/anexosProvider";
import { PatrimonioContext } from "../../../contexts/patrimonioProvider";
import { UserContext } from "../../../contexts/userProvider";

const Cadastro = () => {
  const { anexosByClient } = useContext(AnexosContext);
  const { marcasByFornecedor, modelosByMarca, createPatrimonio } = useContext(PatrimonioContext);
  const { user, setAlert } = useContext(UserContext);
  const [editedData, setEditedData] = useState({
    id_usuario: user.id,
    usuario: user?.nome,
  });
  const [anexos, setAnexos] = useState([]);
  const [marcas, setMarcas] = useState([]);
  const [modelos, setModelos] = useState([]);

  useEffect(() => {
    let idCliente = editedData?.id_cliente;
    if (idCliente) {
      anexosByClient(idCliente).then((anexos) => {
        setAnexos(anexos);
      });
    }
  }, [editedData?.id_cliente]);

  useEffect(() => {
    let idFornecedor = editedData?.id_fornecedor;
    if (idFornecedor) {
      marcasByFornecedor(idFornecedor).then((marcas) => {
        setMarcas(marcas);
      });
    }
  }, [editedData?.id_fornecedor]);

  useEffect(() => {
    let idMarca = editedData?.id_marca;
    if (idMarca) {
      modelosByMarca(idMarca).then((modelos) => {
        setModelos(modelos);
      });
    }
  }, [editedData?.id_marca]);

  const handleInputChange = (e, index, requiredValue) => {
    if (requiredValue) {
      if (e.target.value == requiredValue) {
        setEditedData({ ...editedData, [e.target.name]: e.target.value });
      } else {
        alert('Selecione o anexo estoque');
        setEditedData({ ...editedData, [e.target.name]: '' });
      }
    } else {
      const { name, value } = e.target;
      setEditedData({ ...editedData, [name]: value });
    }
  };

  const handleSaveClick = () => {
    let newPatrimonio = { ...editedData };
    newPatrimonio.created_at = newPatrimonio.updated_at = new Date().toISOString().slice(0, 19).replace('T', ' ');
    if (!newPatrimonio.id_cliente ||
      !newPatrimonio.id_anexo ||
      !newPatrimonio.id_fornecedor ||
      !newPatrimonio.id_marca ||
      !newPatrimonio.id_modelo ||
      !newPatrimonio.nSerie ||
      !newPatrimonio.nMac ||
      !newPatrimonio.nEtiqueta) {
      setAlert({ visible: true, title: "Atenção!", placeholder: 'Todos os campos são obrigatórios.', confirm: false, type: "warning" });
      return;
    } else {
      createPatrimonio(newPatrimonio);
      setEditedData({
        id_usuario: user.id,
        usuario: user?.nome,
      });
    }
  };

  const dados = GetDadosPatrimonio(editedData, anexos, marcas, modelos);
  const componentMap = getComponentMap(handleInputChange);

  return (
    <>
      <div className="patrimonio-form">
        {dados.map((dado, index) => (
          <div className="patrimonio-form-item" key={index}>
            {componentMap["Cadastro"](dado)}
          </div>
        ))}
      </div>

      <button
        className="patrimonio-cadastro-button"
        onClick={handleSaveClick}
      >
        Salvar
      </button>
    </>
  );
};

export default Cadastro;
