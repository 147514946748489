import { useContext, useEffect, useState } from 'react';
import { FaturadorContext } from '../../../../contexts/faturadorProvider';
import { ClientContext } from '../../../../contexts/clientProvider';
import LoadingSpinner from '../../../Loader/load';
import DataTable from '../../../Table';
import FilterDate from '../filterDate';
import { BiX } from "react-icons/bi";

const Geral = () => {
  const { clientes, setIdCliente } = useContext(ClientContext);
  const { patrimonios, loading, total, toBrl, clienteSelecionado, setClienteSelecionado, filterDates, setFilterDates } = useContext(FaturadorContext);
  const [titulo, setTitulo] = useState(null);
  const [filterModal, setFilterModal] = useState(false);
  const [patrimoniosFiltered, setPatrimoniosFiltered] = useState([]);
  const [checkUpdate, setCheckUpdate] = useState(false);

  useEffect(() => {
    if (!clienteSelecionado) return;
    const client = clientes.find((client) => client.nome === clienteSelecionado);
    setIdCliente(client.id);
    setTitulo(client.nome);
  }, []);

  useEffect(() => {
    if (!patrimonios) return;
    let newPats = patrimonios.map((pat) => {
      if (!pat.produto) {
        pat.produto = `${pat.marca_nome} ${pat.modelo_nome}`;
      }
      if (pat.data_implantacao) {
        pat.data_implantacao = formatDate(pat.data_implantacao.split('T')[0]);
        return pat;
      }
      return pat;
    });
    setPatrimoniosFiltered(newPats);
  }, [patrimonios]);

  const headers = [
    patrimonios[0]?.data_implantacao && { label: "Data de Implantação", key: "data_implantacao", sortable: false },
    { label: "Anexo", key: "nome_do_anexo", sortable: true },
    { label: "Setor", key: "setor", sortable: false },
    { label: "Nome", key: "pat_nome", sortable: false },
    { label: "Equipamento", key: "produto", sortable: false },
    { label: "Marca", key: "marca_nome", sortable: false },
    { label: "Modelo", key: "modelo_nome", sortable: false },
    { label: "MAC", key: "nmac", sortable: false },
    { label: "Serial Number", key: "nserie", sortable: false },
    { label: "Nº Etiqueta", key: "netiqueta", sortable: false },
    { label: "Valor", key: "valor_cobrado", sortable: true },
  ].filter((header) => header);

  const handleSelectClient = (e) => {
    const id = e.target.value;
    const selectedClient = clientes.find((client) => client.id === parseInt(id));

    if (selectedClient) {
      setTitulo(selectedClient.nome);
      setIdCliente(id);
    }
    setClienteSelecionado(selectedClient?.nome);
  };

  const handleDateModal = () => {
    setFilterModal(!filterModal);
  };

  const formatDate = (dateString) => {
    if (!dateString) return "";

    const [year, month, day] = dateString.split("-");
    return `${day}-${month}-${year}`;
  };

  const eraseFilter = () => {
    setFilterDates({ dataInicio: null, dataFim: null, checkUpdate: false });
    const client = clientes.find((client) => client.nome === clienteSelecionado);
    setIdCliente(client.id);
  }

  return (
    <>
      <select className="anexos-consulta-select" onChange={handleSelectClient}>
        <option value="">{clienteSelecionado ?? "Selecione o cliente"}</option>
        {clientes &&
          clientes
            .slice()
            .sort((a, b) => a.nome.localeCompare(b.nome))
            .map((client, index) => (
              <option key={index} value={client.id}>
                {client.nome}
              </option>
            ))}
      </select>
      {loading && <LoadingSpinner />}

      {clienteSelecionado && (
        <>
          {!filterDates.dataInicio && !filterDates.dataFim ? (
            <button className='anexos-cadastro-button' onClick={() => setFilterModal(true)}>Filtrar por data de implantação</button>
          ) : (
            <div className="info-container" style={{ marginBottom: '10px' }}>
              <span>
                Data Inicial: {formatDate(filterDates.dataInicio)}
              </span>
              <span>
                Data Final: {formatDate(filterDates.dataFim)}
              </span>
              <span>
                Busca por atualização do patrimônio: {checkUpdate ? 'Sim' : 'Não'}
              </span>
              <BiX onClick={eraseFilter} style={{ color: '#142a4c', cursor: 'pointer' }} />
            </div>
          )}

          <div className="info-container">
            <span>
              Número de Aparelhos Contados: {patrimonios.length}
            </span>
            <span>
              Valor Total de Aparelhos: {toBrl(total)}
            </span>
          </div>

          {patrimoniosFiltered && <DataTable headers={headers} data={patrimonios} itemsPerPage={5} exportButton={true} docTitle={`Dados gerais do cliente ${titulo}`} fontHeader={8} fontData={7} />}
        </>
      )}

      {filterModal && <FilterDate filterDates={filterDates} setFilterDates={setFilterDates} closeModal={handleDateModal} checkUpdate={checkUpdate} setCheckUpdate={setCheckUpdate} />}
    </>
  );
};

export default Geral;
