import React, { useContext, useEffect, useState } from 'react';
import { ClientContext } from '../../../contexts/clientProvider';
import Visualizar from '../Visualizar';
import Servicos from '../Servicos';
import DataTable from '../../Table';
import Loader from '../../Loader/load';
import Equipamentos from '../Equipamentos';
import ItensTable from '../Itens';
import Moviments from '../Moviments';

const Consulta = () => {
    const { clientes, loading, setAlert, deleteCliente, verifyMovimentsByMonth } = useContext(ClientContext);
    const [clients, setClients] = useState(null);
    const [clienteSelecionado, setClienteSelecionado] = useState(null);
    const [servicosPrestados, setServicosPrestados] = useState(null);
    const [type, setType] = useState('');
    const [month, setMonth] = useState('1');
    const [ano, setAno] = useState(new Date().getFullYear());
    const [allMoviments, setAllMoviments] = useState(null);
    const [loadMoviments, setLoadMoviments] = useState(false);
    const componentes = {
        visualizar: <Visualizar cliente={clienteSelecionado} closeModal={closeModal} />,
        servicos: <Servicos closeModal={closeModal} clienteSelecionado={clienteSelecionado} servicosPrestados={servicosPrestados} />,
        equipamentos: <Equipamentos cliente={clienteSelecionado} closeModal={closeModal} />,
        itens: <ItensTable cliente={clienteSelecionado} closeModal={closeModal} />,
        moviments: <Moviments cliente={clienteSelecionado} closeModal={closeModal} month={month} ano={ano} data={allMoviments} />,
    }

    useEffect(() => {
        let newClients = clientes ? clientes : [];
        setClients(newClients);
    }, [clientes]);


    const headers = [
        { label: 'Nome', key: 'nome', sortable: true },
        { label: 'CPF/CNPJ', key: 'cpf_cnpj', sortable: false },
        { label: 'Tipo de Contrato', key: 'tipo_contrato', sortable: true },
        { label: 'Telefone', key: 'telefone', sortable: false },
        {
            label: 'Ações', key: 'actions', sortable: false,
            actions: {
                "visualizar": visualizarClient,
                "vincular": vincularServicosPrestados,
                "equipamentos": equipamentosClient,
                "itens": itensClient,
                // "deletar": deleteClient,

            }
        },
    ];

    function visualizarClient(id) {
        const client = clients.find((client) => client.id === id);
        setClienteSelecionado(client);
        setType('visualizar');
    }

    function deleteClient(id) {
        if (!id) return console.error('ID não informado');
        setAlert({
            type: 'confirm',
            placeholder: 'Deseja realmente deletar o cliente?',
            visible: true,
            confirm: true,
            title: 'Atenção!',
            callback: () => deleteCliente(id),
            params: id
        })
    }

    function equipamentosClient(id) {
        setClienteSelecionado(id);
        setType('equipamentos');
    }

    async function vincularServicosPrestados(id) {
        const client = clients.find((client) => client.id === id);
        setClienteSelecionado({ ...client });
        setType('servicos');
    }

    function itensClient(id) {
        setClienteSelecionado(id);
        setType('itens');
    }

    function closeModal() {
        setClienteSelecionado(null);
        setServicosPrestados(null);
        setType('');
    }

    const handleMoviments = async () => {
        const hasZero = (month) => month < 10 ? "0" + month : month;
        if (!month) return setAlert({ visible: true, title: "Atenção!", placeholder: 'Selecione um mês', confirm: false, type: "warning" });
        let date = hasZero(month) + "/" + ano;
        let dados = await verifyMovimentsByMonth(date);
        if (!dados) return setAlert({ visible: true, title: "Atenção!", placeholder: 'Nenhum movimento encontrado', confirm: false, type: "warning" });
        setAllMoviments(dados);
        setLoadMoviments(true);
        setType('moviments');
    }


    return (
        <>

            {loading && !loadMoviments ? (
                <Loader />
            ) : (
                <div className="cliente-consulta-container">
                    <h1 style={{ fontSize: "24px", fontWeight: "bold", color: "#333" }}>
                        Referência de Data para Alterações em Clientes
                    </h1>
                    <div className="container-modal-header-itens" style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "50%" }}>

                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", flexDirection: "column" }}>
                            <h2 style={{ fontSize: "18px", color: "#555" }}>Selecione o mês</h2>
                            <select
                                onChange={(e) => setMonth(e.target.value)}
                                style={{
                                    padding: "8px",
                                    fontSize: "16px",
                                    borderRadius: "4px",
                                    border: "1px solid #ccc",
                                    outline: "none",
                                    width: "100%",
                                    maxWidth: "300px",
                                }}
                            >
                                <option value="1">Janeiro</option>
                                <option value="2">Fevereiro</option>
                                <option value="3">Março</option>
                                <option value="4">Abril</option>
                                <option value="5">Maio</option>
                                <option value="6">Junho</option>
                                <option value="7">Julho</option>
                                <option value="8">Agosto</option>
                                <option value="9">Setembro</option>
                                <option value="10">Outubro</option>
                                <option value="11">Novembro</option>
                                <option value="12">Dezembro</option>
                            </select>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", flexDirection: "column" }}>
                            <h2 style={{ fontSize: "18px", color: "#555" }}>Selecione o ano</h2>
                            <select
                                onChange={(e) => setAno(e.target.value)}
                                style={{
                                    padding: "8px",
                                    fontSize: "16px",
                                    borderRadius: "4px",
                                    border: "1px solid #ccc",
                                    outline: "none",
                                    width: "100%",
                                    maxWidth: "300px",
                                }}
                            >
                                {Array.from({ length: 10 }, (_, i) => (
                                    <option key={i} value={ano - i}>{ano - i}</option>
                                ))}
                            </select>
                        </div>
                        <button className="open-table-button" onClick={handleMoviments} style={{ marginTop: "20px" }}>
                            Verificar Movimentos do Mês
                        </button>

                    </div>

                    {clients && <DataTable data={clients} headers={headers} itemsPerPage={10} />}
                    {type && componentes[type]}
                </div >
            )}
        </>
    );
};

export default Consulta;
