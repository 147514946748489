import { useState, useEffect, useContext } from "react";
import { AnexosContext } from "../../../contexts/anexosProvider";
import { ClientContext } from "../../../contexts/clientProvider";
import { UserContext } from "../../../contexts/userProvider";
import { PatrimonioContext } from "../../../contexts/patrimonioProvider";
import { BiX } from "react-icons/bi";

const Movimenta = ({ setMovimentaModal, selectedPatrimonios, setNewPatrimonios, newPatrimonios, setSelectedPatrimonios }) => {
  const { clientes } = useContext(ClientContext);
  const { anexosByClient } = useContext(AnexosContext);
  const { user, setAlert } = useContext(UserContext);
  const { movimentarPatrimonios } = useContext(PatrimonioContext);
  const [patrimonios, setPatrimonios] = useState([]);
  const [clients, setClients] = useState(null);
  const [anexos, setAnexos] = useState(null);
  const [movimentaData, setMovimentaData] = useState({});

  useEffect(() => {
    let patIds = selectedPatrimonios ? [...selectedPatrimonios] : [];
    setPatrimonios(patIds);
  }, [selectedPatrimonios]);

  useEffect(() => {
    let newClients = clientes ? clientes : [];
    setClients(newClients);
  }, [clientes]);

  async function handleSelectClient(e) {
    setAnexos(null);
    const idCliente = e.target.value;
    const anexos = await anexosByClient(idCliente);
    setAnexos(anexos);
    setMovimentaData({ ...movimentaData, id_cliente: parseInt(idCliente) });
  }

  async function handleSelectAnexo(e) {
    const idAnexo = e.target.value;
    setMovimentaData({ ...movimentaData, id_anexo: parseInt(idAnexo) });
  }

  const handleCloseModal = () => {
    setMovimentaModal(false);
  };

  const handleMovimenta = async () => {
    if(patrimonios.length === 0) return setAlert({ visible: true, title: "Atenção!", placeholder: "Selecione ao menos um patrimônio para movimentar", confirm: false, type: "warning" });
    let newMovimentaData = { ...movimentaData, usuario: user.nome, id_usuario: user.id, patrimonios: patrimonios };
    newMovimentaData.created_at = newMovimentaData.updated_at = new Date().toISOString().slice(0, 19).replace('T', ' ');

    if (newMovimentaData.patrimonios) {
      newMovimentaData.tipo = newMovimentaData.patrimonios.map((pat) => {
        if (pat?.produto) {
          return 'NFS';
        } else {
          return 'inventario';
        }
      })[0];
    }
    await movimentarPatrimonios(newMovimentaData);
    let patrimonioIds = patrimonios.map(pat => pat.id);

    let patrimoniosN = newPatrimonios.filter((pat) => {
      if (patrimonioIds.includes(pat.id)) {
        return false;
      }
      return true;
    });

    setNewPatrimonios(patrimoniosN);
    setSelectedPatrimonios([]);
    handleCloseModal();
  };


  return (
    <div className="visualizar-modal">
      <div className="visualizar-modal-body" style={{ width: "90%" }}>
        <div className="modal-edit-header" style={{ display: "flex", alignItems: "center" }} >
          <BiX onClick={handleCloseModal} className="fechar-top-button" />
          <h1>Movimentar patrimônio</h1>
        </div>
        <select className="anexos-consulta-select" onChange={(e) => handleSelectClient(e)} style={{maxWidth:'90%'}}>
          <option value="">Selecione o cliente</option>
          {clients &&
            clients
              .slice()
              .sort((a, b) => a?.nome?.localeCompare(b?.nome))
              .map((client, index) => (
                <option key={index} value={client.id}>
                  {client.nome}
                </option>
              ))}
        </select>
        <select className="anexos-consulta-select" onChange={(e) => handleSelectAnexo(e)} disabled={anexos ? false : true} style={{maxWidth:'90%'}}>
          <option value="">Selecione o anexo</option>
          {anexos &&
            anexos
              .slice()
              .sort((a, b) => a?.nome?.localeCompare(b?.nome))
              .map((anexo, index) => (
                <option key={index} value={anexo.id}>
                  {anexo.nome}
                </option>
              ))}
        </select>
        {patrimonios.length > 0 ? (
          <h1 style={{ maxWidth: "100%", textAlign: "center", wordWrap: "break-word" }}>ID dos Patrimônios selecionados: {patrimonios.map((pat) => { return pat.id }).join(',')}</h1>
        ) : (
          <h1>Nenhum patrimônio selecionado</h1>
        )}

        <button className="patrimonio-cadastro-button" onClick={handleMovimenta}>
          Confirmar Movimentação
        </button>
      </div>
    </div>
  );
};

export default Movimenta;
