import React from 'react'
import * as XLSX from 'xlsx';

const PrintToXLS = ({ headers, data, docTitle, hasMonth, footerValues }) => {

  const handlePrintXLS = () => {
    const sheetData = [
      headers.map(header => header.label),
      ...data.map(item => headers.map(header => item[header.key]))
    ];

    if (hasMonth) {
      sheetData.unshift(['Mês de Referência:', hasMonth]);
    }

    if (footerValues && Array.isArray(footerValues)) {
      sheetData.push([...footerValues]);
    }

    const ws = XLSX.utils.aoa_to_sheet(sheetData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, `${docTitle}.xlsx`);
  }

  return (
    <button
      className="patrimonio-cadastro-button"
      style={{ width: "100px" }}
      onClick={handlePrintXLS}
    >
      Gerar XLS
    </button>
  )
}

export default PrintToXLS