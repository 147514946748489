import React, { useEffect, useState } from 'react';
import { TbTriangleFilled, TbTriangleInvertedFilled } from "react-icons/tb";
import Relatorio from '../Relatorio';


const DetailsModal = ({ closeDetailsModal, client, month, ano }) => {
    const [saidas, setSaidas] = useState([]);
    const [entradas, setEntradas] = useState([]);
    const [openRelatoriosTable, setOpenRelatoriosTable] = useState(false);

    useEffect(() => {
        const qtdSaidas = client.moves.filter(move => move.nome_anexo_atual?.toLowerCase() === "estoque").length;
        const qtdEntradas = client.moves.filter(move => move.nome_anexo_atual?.toLowerCase() !== "estoque").length;
        setSaidas(qtdSaidas);
        setEntradas(qtdEntradas);

    }, []);

    return (
        <div className="overlay" onClick={e => {
            if (e.target === e.currentTarget) closeDetailsModal();
        }}>
            {openRelatoriosTable && (<Relatorio closeModal={() => setOpenRelatoriosTable(false)} client={client} month={month} ano={ano} />)}
            <div className="modal">
                <div className="header" >
                    <div ></div>
                    <h2>Detalhes do Cliente</h2>
                    <button className="closeButton" onClick={closeDetailsModal} style={{ fontSize: "45px" }}>
                        &times;
                    </button>
                </div>
                <div className="body">
                    <h2> {client.nome}</h2>
                    <button className="open-table-button" onClick={() => setOpenRelatoriosTable(true)} >
                        Relatorio do Cliente
                    </button>
                    <div style={{ display: "flex", flexWrap: "wrap", gap: "20px", justifyContent: "center" }}>
                        <h3 style={{ color: 'green' }}>Entradas: {entradas}</h3>
                        <h3 style={{ color: 'red' }}>Saídas: {saidas}</h3>
                    </div>
                    <h3 style={{ margin: 0, marginBottom: '10px' }}><u>Movimentações</u></h3>

                    <div className="moved-clients-container">
                        {
                            client.moves.map((move, index) => (
                                <div key={index} className='client-moved-detail'>
                                    <div className='float-triangle' key={index}>
                                        {
                                            move.nome_anexo_atual?.toLowerCase() === "estoque" ? (
                                                <TbTriangleInvertedFilled style={{ color: "red", fontSize: "30px" }} />
                                            ) : (
                                                <TbTriangleFilled style={{ color: "green", fontSize: "30px" }} />
                                            )
                                        }
                                    </div>
                                    <div><p><strong>Patrimônio:</strong> {move.nEtiqueta}</p></div>
                                    <div><p><strong>Anexo Atual:</strong> {move.nome_anexo_atual}</p></div>
                                    {move.movimento_anterior && <div><p><strong>Anexo Anterior:</strong> {move.movimento_anterior.nome_anexo_anterior}</p></div>}
                                    <div><p><strong>Usuário:</strong> {move.usuario}</p></div>
                                    <div><p><strong>Marca:</strong> {move.marca}</p></div>
                                    <div><p><strong>Modelo:</strong> {move.modelo}</p></div>
                                    <div><p><strong>Ramal:</strong> {move.ramal}</p></div>
                                </div>
                            ))
                        }
                    </div>
                </div>

            </div>
        </div>
    );
};

export default DetailsModal;