import React from 'react'

const TecnicaCadastro = ({ newClient, handleInputChange }) => {
    return (
        <div className='visualizar-modal-content-cadastro'>
            <div className='visualizar-modal-content-item-cadastro'>
                <h2>Nome Responsável TI: </h2>
                <input
                    type='text'
                    name='responsavel_ti'
                    value={newClient?.responsavel_ti}
                    onChange={handleInputChange}
                />
            </div>
            <div className='visualizar-modal-content-item-cadastro'>
                <h2>E-mail Responsável TI: </h2>
                <input
                    type='text'
                    name='email_responsavel_ti'
                    value={newClient?.email_responsavel_ti}
                    onChange={handleInputChange}
                />
            </div>
            <div className='visualizar-modal-content-item-cadastro'>
                <h2>Tec - E1</h2>
                <select name='e1' value={newClient?.e1} onChange={handleInputChange}>
                    <option value=''>Selecione</option>
                    <option value='1'>Sim</option>
                    <option value='0'>Não</option>
                </select>
            </div>
            <div className='visualizar-modal-content-item-cadastro'>
                <h2>Número Piloto: </h2>
                <input
                    type='text'
                    name='numero_piloto'
                    value={newClient?.numero_piloto}
                    onChange={handleInputChange}
                />
            </div>
            <div className='visualizar-modal-content-item-cadastro'>
                <h2>Nome Técnico Responsável VoxCity: </h2>
                <input
                    type='text'
                    name='id_tecnico_voxcity'
                    value={newClient?.id_tecnico_voxcity}
                    onChange={handleInputChange}
                />
            </div>
        </div>
    )
}

export default TecnicaCadastro
