import { useContext, useState, useEffect } from 'react'
import { ClientContext } from '../../../contexts/clientProvider'
import { AnexosContext } from '../../../contexts/anexosProvider'
import LinhasEdit from '../../Anexos/Linhas/linhasEdit'
import DataTable from '../../Table'
import LoadingSpinner from '../../Loader/load'


const Consulta = () => {
    const { clientes, setAlert } = useContext(ClientContext);
    const { anexosByClient, linhasByAnexo, deleteLinhaById } = useContext(AnexosContext);
    const [clients, setClients] = useState(null);
    const [anexos, setAnexos] = useState(null);
    const [linhas, setLinhas] = useState(null);
    const [linhaSelecionada, setLinhaSelecionada] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        let newClients = clientes ? clientes : [];
        setClients(newClients);
    }, [clientes]);

    async function handleSelectClient(e) {
        setAnexos(null)
        const idCliente = e.target.value
        const anexos = await anexosByClient(idCliente)
        setAnexos(anexos)
    }

    async function handleSelectAnexo(e) {
        setLoading(true)
        const idAnexo = e.target.value
        const linhas = await linhasByAnexo(idAnexo)
        setLinhas(linhas)
        setLoading(false)
    }
    const visualizar = (linha) => {
        const linhaSelecionada = linhas.find(l => l.id === linha)
        setLinhaSelecionada(linhaSelecionada)
    }

    const deletar = (linha) => {
        if (!linha) return console.error('ID não informado');
        setAlert({
            type: 'confirm',
            placeholder: 'Deseja realmente deletar a linha?',
            visible: true,
            confirm: true,
            title: 'Atenção!',
            callback: () => deleteLinhaById(linha),
            params: linha
        })
    }

    const closeEditModal = () => {
        setLinhaSelecionada(null)
    }

    const headers = [
        { label: "Linha", key: "linha", sortable: true },
        { label: "Operadora", key: "operadora", sortable: false },
        { label: "Status", key: "status", sortable: false },
        { label: "Status Atual", key: "status_atual", sortable: false },
        { label: "Ações", key: "actions", sortable: false, actions: { "visualizar": visualizar, "deletar": deletar } },
    ]

    return (
        <div className='anexos-consulta-contander'>
            <select className='anexos-consulta-select' onChange={(e) => handleSelectClient(e)}>
                <option value=''>Selecione o cliente</option>
                {clients &&
                    clients
                        .slice()
                        .sort((a, b) => a.nome.localeCompare(b.nome))
                        .map((client, index) => (
                            <option key={index} value={client.id}>
                                {client.nome}
                            </option>
                        ))}
            </select>
            <select className='anexos-consulta-select' onChange={(e) => handleSelectAnexo(e)} disabled={anexos ? false : true}>
                <option value=''>Selecione o anexo</option>
                {anexos &&
                    anexos
                        .slice()
                        .sort((a, b) => a.nome.localeCompare(b.nome))
                        .map((anexo, index) => (
                            <option key={index} value={anexo.id}>
                                {anexo.nome}
                            </option>
                        ))}
            </select>
            {linhas ? <DataTable headers={headers} data={linhas} itemsPerPage={10} /> : null }
            {linhaSelecionada && <LinhasEdit closeEditModal={closeEditModal} linha={linhaSelecionada} />}
            {loading && <LoadingSpinner />}
        </div>
    )
}

export default Consulta
