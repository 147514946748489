import { useEffect, useState, useContext } from 'react';
import { ClientContext } from '../../../../contexts/clientProvider';
import { cpf, cnpj } from 'cpf-cnpj-validator';
import { UserContext } from '../../../../contexts/userProvider';
import { getDadosGeral, getComponentMap } from '../../../General/Format/cliente';

const Geral = ({ client, setClient, handleFecharClick }) => {
    const { setAlert } = useContext(UserContext);
    const { updateCliente, estados } = useContext(ClientContext);
    const [type, setType] = useState('Geral');
    const [editedData, setEditedData] = useState({});
    const [dados, setDados] = useState({ estado: '', cidade: '' });
    const [municipios, setMunicipios] = useState([]);

    useEffect(() => {
        setEditedData(client);
    }, []);

    const fetchData = async (estado) => {
        try {
            const response = await fetch(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${estado}/municipios`);
            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Erro ao recuperar dados:', error);
        }
    };

    useEffect(() => {
        fetchData(editedData.estado).then(setMunicipios);
    }, [editedData.estado]);

    useEffect(() => {
        fetchData(client.estado).then(data => {
            const cidade = data.find(cidade => cidade.id === parseInt(client.cidade))?.nome;
            const estadoSigla = estados.find(estado => estado.id === client.estado)?.nome;
            setDados({ estado: estadoSigla, cidade: cidade || 'Nenhum dado cadastrado.' });
        });
    }, []);


    const handleEditClick = () => {
        setType('Edit');
        setEditedData(client);
    };

    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleSaveClick = async () => {
        const invalidEmailKey = Object.keys(editedData).find(key => key.toLowerCase().includes('email') && editedData[key] && !isValidEmail(editedData[key]));
        if (invalidEmailKey) {
            setAlert({
                type: 'warning', placeholder: `E-mail inválido no campo ${invalidEmailKey}`, visible: true, confirm: false, title: 'Atenção!'
            });
            return;
        }
        let cpf_cnpj = editedData.cpf_cnpj.toString().replace(/\D/g, '');
        let isCpf = cpf_cnpj.toString().length <= 11;
        if (isCpf && !cpf.isValid(cpf_cnpj)) {
            setAlert({ visible: true, title: "Atenção!", placeholder: "CPF inválido.", confirm: false, type: "warning" });
            return;
        }

        if ((!isCpf && !cnpj.isValid(cpf_cnpj)) || !editedData.nome) {
            setAlert({ visible: true, title: "Atenção!", placeholder: "Preencha todos os campos nome e CNPJ corretamente.", confirm: false, type: "warning" });
            return;
        }
        let editedDataCopy = { ...editedData };
        if (cpf_cnpj.length > 11) {
            editedDataCopy.cpf_cnpj = cnpj.format(cpf_cnpj);
        } else {
            editedDataCopy.cpf_cnpj = cpf.format(cpf_cnpj);
        }
        setClient(await updateCliente(editedDataCopy));
        setType('Geral');
        handleFecharClick();
    }


    const handleCancelClick = () => {
        setType('Geral');
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditedData((prevData) => ({ ...prevData, [name]: value }));
    };

    const componentMap = getComponentMap(handleFecharClick, handleCancelClick, handleSaveClick, handleEditClick, handleInputChange);
    const dadosGeral = getDadosGeral(editedData, dados, estados, municipios);


    return (
        <>
            <div className='visualizar-modal-content'>
                {dadosGeral.map((dado, index) => (
                    <div className='visualizar-modal-content-item' key={index}>{componentMap[type](dado)}</div>
                )
                )}

            </div>
            <div className='visualizar-edit-box'>
                {componentMap[`buttons${type}`]()}
            </div>
        </>
    )
}

export default Geral