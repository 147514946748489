import React, { useContext } from 'react';
import { PatrimonioContext } from '../../../contexts/patrimonioProvider';
const defaultItem = {
    descricao: '',
    codigo: '',
    tipo: '',
    quantidade_contratada: '',
    quantidade_implantada: '',
    grupo: '',
    unidade: '',
    valor_unitario: ''
}
const CadastrarItens = ({ newClient, handleInputChange }) => {
    const { grupos } = useContext(PatrimonioContext);

    const handleItemChange = (index, field, value) => {
        const updatedItens = newClient.itens?.map((item, i) =>
            i === index ? { ...item, [field]: value } : item
        );
        handleInputChange({ target: { name: 'itens', value: updatedItens } });
    };

    const addItem = () => {
        const newItem = defaultItem;
        newClient.itens = newClient.itens || [];
        const updatedItens = [...newClient.itens, newItem];
        handleInputChange({ target: { name: 'itens', value: updatedItens } });
    };

    const removeItem = (index) => {
        const updatedItens = newClient.itens?.filter((_, i) => i !== index);
        handleInputChange({ target: { name: 'itens', value: updatedItens } });
    };

    return (
        <div style={{ width: '100%' }}>
            {newClient.itens?.map((item, index) => (
                <div
                    key={index}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                        width: '100%',
                    }}
                >
                    <div className="visualizar-modal-content-item-cadastro content-itens" style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%'
                    }}>
                        <h2
                            style={{
                                width: '100%',
                                textAlign: 'center',
                                marginBottom: '10px',
                            }}>Descrição:</h2>
                        <textarea
                            type="text"
                            value={item.descricao}
                            onChange={(e) =>
                                handleItemChange(index, 'descricao', e.target.value)
                            }
                            required
                            aria-label={`Descrição do item ${index + 1}`}
                        />
                    </div>
                    <div className="visualizar-modal-content-item-cadastro content-itens">
                        <h2>Código:</h2>
                        <input
                            type="text"
                            value={item.codigo}
                            onChange={(e) =>
                                handleItemChange(index, 'codigo', e.target.value)
                            }
                            aria-label={`Código do item ${index + 1}`}
                        />
                    </div>
                    <div className="visualizar-modal-content-item-cadastro content-itens">
                        <h2>Tipo:</h2>
                        <select
                            value={item.tipo}
                            onChange={(e) =>
                                handleItemChange(index, 'tipo', e.target.value)
                            }
                            required
                            aria-label={`Tipo do item ${index + 1}`}
                        >
                            <option value="">Selecione o tipo</option>
                            <option value="Equipamento">Equipamento</option>
                            <option value="Licença">Licença</option>
                            <option value="Serviço">Serviço</option>
                            <option value="Minutos">Minutos</option>
                            <option value="Valor">Valor</option>
                        </select>
                    </div>
                    <div className="visualizar-modal-content-item-cadastro content-itens">
                        <h2>Quantidade Contratada:</h2>
                        <input
                            type="text"
                            value={item.quantidade_contratada}
                            onChange={(e) =>
                                !isNaN(e.target.value) &&
                                handleItemChange(index, 'quantidade_contratada', e.target.value)
                            }
                            required
                            aria-label={`Quantidade Contratada do item ${index + 1}`}
                        />
                    </div>
                    <div className="visualizar-modal-content-item-cadastro content-itens">
                        <h2>Grupo:</h2>
                        <select
                            value={item.grupo}
                            onChange={(e) =>
                                handleItemChange(index, 'grupo', e.target.value)
                            }
                            required
                            aria-label={`Grupo do item ${index + 1}`}
                        >
                            <option value="">Selecione um grupo</option>
                            {grupos.map((grupo, i) => (
                                <option key={i} value={grupo.id}>
                                    {grupo.nome}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="visualizar-modal-content-item-cadastro content-itens">
                        <h2>Unidade:</h2>
                        <select
                            value={item.unidade}
                            onChange={(e) =>
                                handleItemChange(index, 'unidade', e.target.value)
                            }
                            required
                            aria-label={`Unidade do item ${index + 1}`}
                        >
                            <option value="">Selecione a unidade</option>
                            <option value="Mês">Mês</option>
                            <option value="Unitário">Unitário</option>
                            <option value="Minutos">Minutos</option>
                        </select>
                    </div>
                    <div className="visualizar-modal-content-item-cadastro content-itens">
                        <h2>Valor Unitário:</h2>
                        <input
                            type="text"
                            value={item.valor_unitario}
                            onChange={(e) =>
                                handleItemChange(index, 'valor_unitario', e.target.value)
                            }
                            required
                            aria-label={`Valor Unitário do item ${index + 1}`}
                        />
                    </div>
                    <button
                        type="button"
                        onClick={() => removeItem(index)}
                        className="remove-item-button"
                        style={{
                            backgroundColor: '#ff4d4d',
                            color: '#fff',
                            border: 'none',
                            padding: '5px 10px',
                            borderRadius: '3px',
                            cursor: 'pointer',
                            width: '25%',
                            marginBottom: '6%',
                        }}
                    >
                        Remover Item
                    </button>
                </div>
            ))}
            <button
                type="button"
                onClick={addItem}
                className="add-item-button"
                style={{
                    backgroundColor: '#4CAF50',
                    color: '#fff',
                    border: 'none',
                    padding: '10px 15px',
                    borderRadius: '5px',
                    cursor: 'pointer',
                    marginRight: '10px',
                }}
            >
                Adicionar Item
            </button>
        </div>
    );
};

export default CadastrarItens;
