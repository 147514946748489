import { useState } from "react";
import Geral from "../Relatorio/Types/geral";
import Setor from "../Relatorio/Types/setor";
import Anexos from "../Relatorio/Types/anexos";
import "./styles.css";

const Relatorio = () => {
  const [type, setType] = useState("Geral");

  const relatoriosType = {
    "Geral": <Geral />,
    "Setor": <Setor />,
    "Anexos": <Anexos />,

  };

  const relatoriosLabels = Object.keys(relatoriosType);

  return (
    <div className="faturador-relatorios-container">
      <div className="faturador-relatorios-header">
        {relatoriosLabels.map((label) => (
          <button
            key={label}
            className="faturador-relatorios-button"
            onClick={() => setType(label)}
            style={
              type === label
                ? { borderBottom: "1px solid #9ac31c", color: "#9ac31c" }
                : null
            }
          >
            {label}
          </button>
        ))}
      </div>
      <div className="faturador-relatorios-body">{relatoriosType[type]}</div>
    </div>
  );
};

export default Relatorio;
