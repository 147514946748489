import { createContext, useEffect, useState, useContext } from "react";
import { UserContext } from "./userProvider";
import apiUser from "../apiUser";
import { PatrimonioContext } from "./patrimonioProvider";

export const ItensContext = createContext();

export const ItensProvider = ({ children }) => {
    const { setAlert } = useContext(UserContext);
    const { grupos } = useContext(PatrimonioContext);
    const [itens, setItens] = useState([]);
    const [loading, setLoading] = useState(false);

    const getItensByClient = async (id) => {
        try {
            setLoading(true);
            setItens([]);

            const response = await apiUser.post("/itens/dados", { id_cliente: id });
            const { success, message, dados } = response.data;
            if (success) {
                let newDados = dados.map((item) => {
                    item.valor_unitario = item.valor_unitario || 0;
                    item.quantidade_contratada = item.quantidade_contratada || 0;
                    item.quantidade_implantada = item.quantidade_implantada || 0;
                    item.valor_total = parseFloat(item.valor_unitario) * parseFloat(item.quantidade_contratada);
                    item.valor_mes = parseFloat(item.valor_unitario) * parseFloat(item.quantidade_implantada);
                    item.grupo_nome = grupos.find((grupo) => grupo.id == item.grupo)?.nome;
                    return item;
                });
                setItens(newDados);
                setLoading(false);
                return newDados;
            } else {
                setAlert({ visible: true, title: "Atenção!", placeholder: message, confirm: false, type: "warning" });
            }
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    const createItem = async (item) => {
        try {
            const response = await apiUser.post("/itens/create", item);
            const { success, message } = response.data;
            if (success) {
                setAlert({ visible: true, title: "Sucesso!", placeholder: message, confirm: false, type: "success" });
                getItensByClient();
            } else {
                setAlert({ visible: true, title: "Atenção!", placeholder: message, confirm: false, type: "warning" });
            }
        } catch (error) {
            console.log(error);
        }
    };

    const updateItens = (itens, cliente_id) => {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await apiUser.post("/itens/update", { itens, cliente_id });
                const { success, message, dados } = response.data;
                resolve({ success, message, dados });
            } catch (error) {
                console.log(error);
                reject(error);
            }
        });
    };

    const deleteItens = (itens) => {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await apiUser.post(`/itens/delete`, { itens });
                const { success, message } = response.data;
                resolve({ success, message });
            } catch (error) {
                console.log(error);
                reject(error);
            }
        });
    };

    const updateValorImplantada = async (item) => {
        if (!item || !item.id) {
            setAlert({ visible: true, title: "Atenção!", placeholder: "Sem dados para atualizar", confirm: false, type: "warning" });
            return;

        }
        try {
            const response = await apiUser.post("/itens/updateQuantidadeImplantada", {
                id: item.id,
                quantidade_implantada: item.quantidade_implantada
            });
            const { success, message } = response.data;
            if (success) {
                setAlert({ visible: true, title: "Sucesso!", placeholder: message, confirm: false, type: "success" });
            } else {
                setAlert({ visible: true, title: "Atenção!", placeholder: message, confirm: false, type: "warning" });
            }
        } catch (error) {
            console.log(error);
            setAlert({ visible: true, title: "Atenção!", placeholder: "Erro ao atualizar quantidade implantada", confirm: false, type: "warning" });
        }
    }

    return (
        <ItensContext.Provider value={{
            itens,
            setItens,
            getItensByClient,
            deleteItens,
            updateItens,
            createItem,
            updateValorImplantada,
            loading,
        }}>
            {children}
        </ItensContext.Provider>
    );
};