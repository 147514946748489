import { useState, useContext, useEffect } from 'react'
import { ClientContext } from '../../../../contexts/clientProvider'
import { getDadosFinanceiro, getComponentMap } from '../../../General/Format/cliente';

const Financeiro = ({ client, setClient, handleFecharClick }) => {
    const { updateCliente } = useContext(ClientContext);
    const [type, setType] = useState('Geral');
    const [editedData, setEditedData] = useState({});
    const [dados, setDados] = useState([]);

    const handleEditClick = () => {
        setType('Edit');
        setEditedData(client);
    };

    const handleSaveClick = async () => {
        const newClient = await updateCliente(editedData);
        setClient(newClient);
        setType('Geral');
        handleFecharClick();
    };

    const handleCancelClick = () => {
        setType('Geral');
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditedData((prevData) => ({ ...prevData, [name]: value }));
    };


    const splitEmail = (email) => {
        if (email) {
            let splitEmail = email.split(';');
            let newEmail = splitEmail.map((email, index) => {
                return <p key={index}>{email}</p>
            })
            return newEmail;
        }
    }

    useEffect(() => {
        setEditedData(client);
    }, []);
    useEffect(() => {
        let newDados = getDadosFinanceiro(editedData, splitEmail);
        setDados(newDados);
    }, [editedData]);

    const componentMap = getComponentMap(
        handleFecharClick,
        handleCancelClick,
        handleSaveClick,
        handleEditClick,
        handleInputChange,
        type
    );


    return (
        <>
            <div className='visualizar-modal-content'>
                {dados.map((dado, index) => {
                    return <div className='visualizar-modal-content-item' key={index}>
                        {componentMap[type](dado)}
                    </div>
                })}
            </div>
            <div className='visualizar-edit-box'>
                {componentMap[`buttons${type}`]()}
            </div>
        </>
    )
}

export default Financeiro
