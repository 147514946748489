import Dashcard from "../DashCard";
import FormComp from "../FormsComp";

export const getDadosAnexo = (anexo) => {
    return [
        {
            titulo: 'Situação',
            valor: anexo?.ativo  === '1' ? 'Ativo' : 'Desativado',
            valorSelect: anexo?.ativo === '1' ? '1' : '0',
            name: 'ativo',
            tipo: 'Select',
            options: [ { id: '1', nome: 'Ativo' }, { id: '0', nome: 'Desativado' }]
        },
        {
            titulo: 'Anexo',
            valor: anexo?.nome || 'Nenhum dado cadastrado.',
            name: 'nome',
            tipo: 'Input'
        },
        {
            titulo: 'Endereço',
            valor: anexo?.endereco || 'Nenhum dado cadastrado.',
            name: 'endereco',
            tipo: 'Input'
        },
        {
            titulo: 'Bairro',
            valor: anexo?.bairro || 'Nenhum dado cadastrado.',
            name: 'bairro',
            tipo: 'Input'
        },
        {
            titulo: 'Complemento',
            valor: anexo?.complemento || 'Nenhum dado cadastrado.',
            name: 'complemento',
            tipo: 'Input'
        },
        {
            titulo: 'Telefone',
            valor: anexo?.telefone || 'Nenhum dado cadastrado.',
            name: 'telefone',
            tipo: 'Input',
            disabled: true
        },
        {
            titulo: 'Operadora',
            valor: anexo?.nome_operadora || 'Nenhum dado cadastrado.',
            name: 'nome_operadora',
            tipo: 'Input',
            disabled: true
        },
        {
            titulo: 'Observações',
            valor: anexo?.observacoes || 'Nenhum dado cadastrado.',
            name: 'observacoes',
            tipo: 'Input'
        },
        {
            titulo: 'Status do local',
            valor: anexo?.status_local || 'Nenhum dado cadastrado.',
            name: 'status_local',
            tipo: 'Select',
            options: [{ id: 'INSTALADO', nome: 'INSTALADO' }, { id: 'PENDENTE', nome: 'PENDENTE', }, { id: 'NÃO INSTALADO', nome: 'NÃO INSTALADO' }]

        },
        {
            titulo: 'Observações do local',
            valor: anexo?.observacoes_local || 'Nenhum dado cadastrado.',
            name: 'observacoes_local',
            tipo: 'Input',
            disabled: true
        },
        {
            titulo: 'Paço Municipal',
            valor: anexo?.paco  === '1' ? 'Sim' : 'Não',
            valorSelect: anexo?.paco === '1' ? '1' : '0',
            name: 'paco',
            tipo: 'Select',
            options: [{ id: '1', nome: 'Sim' }, { id: '0', nome: 'Não' }],
        },
    ]
}

export const getComponentMap = (closeModal, handleCancelClick, handleSaveClick, handleEditClick, handleInputChange) => {
    return {
        Geral: (dado) => <Dashcard titulo={dado.titulo} valor={dado.valor} />,
        Edit: (dado) => <FormComp dados={dado} handleInputChange={handleInputChange} />,
        buttonsEdit: () => (
            <>
                <button className='visualizar-modal-button' onClick={handleCancelClick}>Cancelar</button>
                <button className='visualizar-modal-button' onClick={handleSaveClick}>Salvar</button>
            </>
        ),
        buttonsGeral: () => (
            <>
                <button className='visualizar-modal-button' onClick={() => closeModal()}>Fechar</button>
                <button className='visualizar-modal-button' onClick={handleEditClick}>Editar</button>
            </>
        )
    }
}

export const anexosCadastro = (anexo) => {
    return [
        {
            titulo: 'Anexo',
            valor: anexo?.nome || 'Nenhum dado cadastrado.',
            name: 'nome',
            tipo: 'Input'
        },
        {
            titulo: 'Endereço',
            valor: anexo?.endereco || 'Nenhum dado cadastrado.',
            name: 'endereco',
            tipo: 'Input'
        },
        {
            titulo: 'Bairro',
            valor: anexo?.bairro || 'Nenhum dado cadastrado.',
            name: 'bairro',
            tipo: 'Input'
        },
        {
            titulo: 'Complemento',
            valor: anexo?.complemento || 'Nenhum dado cadastrado.',
            name: 'complemento',
            tipo: 'Input'
        },
        {
            titulo: 'Observações',
            valor: anexo?.observacoes || 'Nenhum dado cadastrado.',
            name: 'observacoes',
            tipo: 'Input'
        },
        {
            titulo: 'Status do local',
            valor: anexo?.status_local || 'Nenhum dado cadastrado.',
            name: 'status_local',
            tipo: 'Select',
            options: [{ id: '', nome: 'Selecione' }, { id: 'INSTALADO', nome: 'INSTALADO' }, { id: 'PENDENTE', nome: 'PENDENTE', }, { id: 'NÃO INSTALADO', nome: 'NÃO INSTALADO' }]

        },
        {
            titulo: 'Observações do local',
            valor: anexo?.observacoes_local || 'Nenhum dado cadastrado.',
            name: 'observacoes_local',
            tipo: 'Input',
        },
        {
            titulo: 'Paço Municipal',
            valor: anexo?.paco === '1' ? 'Sim' : 'Não',
            valorSelect: anexo?.paco === '1' ? '1' : '0',
            name: 'paco',
            tipo: 'Select',
            options: [{ id: '1', nome: 'Sim' }, { id: '0', nome: 'Não' }]
        },
    ]
}

export const cadastroComponentMap = (handleInputChange) => {
    return {
        Cadastro: (dado) => <FormComp dados={dado} handleInputChange={handleInputChange} />,
    }
}

export const anexosLinhas = (linha) => {
    return [
        {
            titulo: 'Telefone',
            valor: linha?.linha || 'Nenhum dado cadastrado.',
            name: 'linha',
            tipo: 'Input',
        },
        {
            titulo: 'Operadora',
            valor: linha?.operadora || 'Nenhum dado cadastrado.',
            name: 'operadora',
            tipo: 'Select',
            options: [{ id: '', nome: 'Selecione' }, { id: 'OI', nome: 'OI' }, { id: 'VIVO', nome: 'VIVO' }, { id: 'ALGAR', nome: 'ALGAR' }, { id: 'TIP', nome: 'TIP' }, { id: 'UNIFIQUE', nome: 'UNIFIQUE' }, { id: 'MHNET', nome: 'MHNET' }]
        },
        {
            titulo: 'Status inicial',
            valor: linha?.status || 'Nenhum dado cadastrado.',
            name: 'status',
            tipo: 'Select',
            options: [{ id: '', nome: 'Selecione' }, { id: 'MANTER', nome: 'MANTER' }, { id: 'SOLICITAR PORTABILIDADE', nome: 'SOLICITAR PORTABILIDADE', }, { id: 'CANCELAR', nome: 'CANCELAR' }, { id: 'SOLICITAR TRANSFERENCIA', nome: 'SOLICITAR TRANSFERENCIA' }]
        }
    ]
}

export const faturarEquipamentosLinhas = (linha) => {
    return [
        {
            titulo: 'Marca',
            valor: linha?.marca_nome || 'Nenhum dado cadastrado.',
            name: 'linha',
            tipo: 'Select',
            options: [{ id: linha.id, nome: linha?.marca_nome  }]
        },
        {
            titulo: 'Modelo',
            valor: linha?.modelo_nome || 'Nenhum dado cadastrado.',
            name: 'modelo',
            tipo: 'Select',
            options: [{ id: linha.id, nome: linha?.modelo_nome }]
        },
        {
            titulo: 'Valor',
            valor: linha?.valor_cobrado || 'Nenhum dado cadastrado.',
            name: 'valor',
            tipo: 'Input',
           
        }
    ]
}

export const linhasComponentMap = (handleInputChange) => {
    return {
        Linhas: (dado) => <FormComp dados={dado} handleInputChange={handleInputChange} />,
    }
}

export const editLinhas = (linha) => {
    return [
        {
            titulo: 'Telefone',
            valor: linha?.linha || 'Nenhum dado cadastrado.',
            name: 'linha',
            tipo: 'Input',
        },
        {
            titulo: 'Operadora',
            valor: linha?.operadora || 'Nenhum dado cadastrado.',
            name: 'operadora',
            tipo: 'Select',
            options: [{ id: '', nome: 'Selecione' }, { id: 'OI', nome: 'OI' }, { id: 'VIVO', nome: 'VIVO' }, { id: 'ALGAR', nome: 'ALGAR' }, { id: 'TIP', nome: 'TIP' }, { id: 'UNIFIQUE', nome: 'UNIFIQUE' }, { id: 'MHNET', nome: 'MHNET' }]
        },
        {
            titulo: 'Status inicial',
            valor: linha?.status || 'Nenhum dado cadastrado.',
            name: 'status',
            tipo: 'Select',
            options: [{ id: '', nome: 'Selecione' }, { id: 'MANTER', nome: 'MANTER' }, { id: 'SOLICITAR PORTABILIDADE', nome: 'SOLICITAR PORTABILIDADE', }, { id: 'CANCELAR', nome: 'CANCELAR' }, { id: 'SOLICITAR TRANSFERENCIA', nome: 'SOLICITAR TRANSFERENCIA' }]
        },
        {
            titulo: 'Status atual',
            valor: linha?.status_atual || 'Nenhum dado cadastrado.',
            name: 'status_atual',
            tipo: 'Select',
            options: [{ id: '', nome: 'Selecione' }, { id: 'PORTABILIDADE SOLICITADA', nome: 'PORTABILIDADE SOLICITADA' }, { id: 'CANCELAMENTO SOLICITADO', nome: 'CANCELAMENTO SOLICITADO', }, { id: 'TRANSFERENCIA SOLICITADA', nome: 'TRANSFERENCIA SOLICITADA' }, { id: 'PORTADA', nome: 'PORTADA' }, { id: 'CANCELADA', nome: 'CANCELADA' }]
        },
    ]
}

export const editLinhasComponentMap = (handleInputChange, handleSaveClick, handleCancelClick, handleEditClick, closeEditModal) => {
    return {
        Geral: (dado) => <Dashcard titulo={dado.titulo} valor={dado.valor} />,
        Edit: (dado) => <FormComp dados={dado} handleInputChange={handleInputChange} />,
        buttonsEdit: () => (
            <>
                <button className='edit-modal-button' onClick={() => handleCancelClick()}>Cancelar</button>
                <button className='edit-modal-button' onClick={() => handleSaveClick()}>Salvar</button>
            </>
        ),
        buttonsGeral: () => (
            <>
                <button className='edit-modal-button' onClick={() => closeEditModal()}>Fechar</button>
                <button className='edit-modal-button' onClick={() => handleEditClick()}>Editar</button>
            </>
        )
    }
}

export const editLinhasFaturadorMap = (handleInputChange, handleSaveClick, handleCancelClick, handleEditClick, closeEditModal) => {
    return {
        Geral: (dado) => <Dashcard titulo={dado.titulo} valor={dado.valor} />,
        Edit: (dado) => <FormComp dados={dado} handleInputChange={handleInputChange} />,
        buttonsEdit: () => (
            <>
                <button className='edit-modal-button' onClick={() => handleCancelClick()}>Cancelar</button>
                <button className='edit-modal-button' onClick={() => handleSaveClick()}>Salvar</button>
            </>
        ),
        buttonsGeral: () => (
            <>
                <button className='edit-modal-button' onClick={() => closeEditModal()}>Fechar</button>
                <button className='edit-modal-button' onClick={() => handleEditClick()}>Editar</button>
            </>
        )
    }
}