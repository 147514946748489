import { useState, useEffect, useContext } from "react";
import { BiX } from "react-icons/bi";
import DataTable from "../../Table";
import LoadingSpinner from "../../Loader/load";
import { ClientContext } from "../../../contexts/clientProvider";
import { ItensContext } from "../../../contexts/ItensProvider"; // Supondo que exista um contexto para itens
import "./styles.css";
import { PatrimonioContext } from "../../../contexts/patrimonioProvider";

const Itens = ({ cliente, closeModal }) => {
    const { clientes } = useContext(ClientContext);
    const { getItensByClient, itens, loading, setItens, updateValorImplantada, } = useContext(ItensContext);
    const { verifyMovimentsByCliente } = useContext(ClientContext);
    const [clienteSelecionado, setClienteSelecionado] = useState(null);
    const [editedValue, setEditedValue] = useState(null);
    const [month, setMonth] = useState("Janeiro");
    const [totalMonth, setTotalMonth] = useState(0);
    const [total, setTotal] = useState(0);

    useEffect(() => {
        getData()

    }, [cliente]);

    useEffect(() => {
        calculateTotals()
    }, [itens]);

    const getData = async () => {
        const client = clientes.find((client) => client.id === cliente);
        setClienteSelecionado(client);
        getItensByClient(client.id);
        calculate();
    }
    const editValue = (e, item, key) => {
        let value = e.target.value;
        if (isNaN(value)) {
            return;
        }
        setEditedValue({ ...item, [key]: e.target.value });
        let newItens = itens.map((i) => {
            if (i.id === item.id) {
                let newValue = parseFloat(e.target.value);
                i.valor_mes = parseFloat(i.valor_unitario) * newValue;
                return { ...i, [key]: e.target.value };
            }

            return i;
        });
        setItens([...newItens]);

    }
    const saveEdit = () => {
        updateValorImplantada(editedValue);
        setEditedValue(null);
    }

    const columns = [
        { label: "Descrição", key: "descricao" },
        { label: "Tipo", key: "tipo" },
        { label: "Quantidade Contratada", key: "quantidade_contratada" },
        {
            label: "Quantidade Implantada",
            key: "quantidade_implantada",
            editable: editValue,
            saveEdit: saveEdit
        },
        { label: "Grupo", key: "grupo_nome" },
        { label: "Unidade", key: "unidade" },
        { label: "Valor Unitário", key: "valor_unitario", type: "money" },
        { label: "Valor Implantado", key: "valor_mes", type: "money" },
        { label: "Valor Mensal Contratado", key: "valor_total", type: "money" },
    ];
    const verifyClick = (e, closeModal) => {
        if (e.target === e.currentTarget) closeModal();
    }

    const calculate = () => {
        let newItens = itens.length > 0 ? [...itens] : [];
        newItens = newItens.map((item) => {
            item.valor_mes = parseFloat(parseFloat(item.valor_unitario) * parseFloat(item.quantidade_implantada))
            return item;
        });
        setItens([...newItens]);
        calculateTotals()
    }
    const calculateTotals = () => {
        let newItens = itens.length > 0 ? [...itens] : [];
        let newTotalMonth
        let newTotal
        if (newItens.length > 0) {
            newTotalMonth = newItens.reduce((acc, item) => {
                return parseFloat(acc) + parseFloat(item.valor_mes);
            }, 0);
            newTotal = newItens.reduce((acc, item) => {
                return parseFloat(acc) + parseFloat(item.valor_total);
            }
                , 0);
        } else {
            newTotal = 0;
            newTotalMonth = 0;
        }
        setTotalMonth(newTotalMonth.toFixed(2));
        setTotal(newTotal.toFixed(2));
    }

    const handleMoviments = async () => {
        verifyMovimentsByCliente(clienteSelecionado.id, month);
    }
    return (
        <div
            className="container-modal-itens"

            onClick={(e) => verifyClick(e, closeModal)}
        >
            <div className="container-modal-content-itens">
                <div
                    className="container-modal-header-itens"
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <h1 style={{ fontSize: "24px", fontWeight: "bold", color: "#333" }}>
                        Itens - {clienteSelecionado && clienteSelecionado.nome}
                    </h1>
                    <BiX
                        onClick={() => closeModal()}
                        className="fechar-top-button"
                        style={{
                            fontSize: "24px",
                            cursor: "pointer",
                            color: "#333",
                        }}
                    />
                    <div style={{ width: "50%", display: "flex", justifyContent: "flex-end", alignItems: "center",gap: "20px" }}>
                        <h2 style={{ fontSize: "18px", color: "#555", }}>Selecione o mês</h2>
                        <select
                            onChange={(e) => setMonth(e.target.value)}
                            style={{
                                padding: "8px",
                                fontSize: "16px",
                                borderRadius: "4px",
                                border: "1px solid #ccc",
                                outline: "none",
                                width: "100%",
                                maxWidth: "300px",
                            }}
                        >
                            <option value="1">Janeiro</option>
                            <option value="2">Fevereiro</option>
                            <option value="3">Março</option>
                            <option value="4">Abril</option>
                            <option value="5">Maio</option>
                            <option value="6">Junho</option>
                            <option value="7">Julho</option>
                            <option value="8">Agosto</option>
                            <option value="9">Setembro</option>
                            <option value="10">Outubro</option>
                            <option value="11">Novembro</option>
                            <option value="12">Dezembro</option>
                        </select>
                    </div>

                </div>
                {/* <div style={{ marginBottom: "20px" }}>
                    <div>
                        <button className="open-table-button" onClick={handleMoviments}>
                            Verificar Movimentos
                        </button>
                    </div>
                </div> */}
                {!itens && loading ? (
                    <LoadingSpinner />
                ) : (
                    <DataTable
                        data={itens || []}
                        noSort={true}
                        headers={columns}
                        onSelectionChange={(selectedItems) => console.log(selectedItems)}
                        exportButton={true}
                        docTitle={`Itens - ${clienteSelecionado?.nome || "Cliente"}`}
                        hasMonthSelection={month}
                        footerValues={[`Total do Mês: R$ ${totalMonth}`, `Total Contratado: R$ ${total}`]}
                        fontHeader={10}
                        fontData={8.7}
                    />
                )}
            </div>
        </div >
    );
};

export default Itens;