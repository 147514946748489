import React, { useContext, useEffect, useState } from 'react';
import { PatrimonioContext } from '../../../../contexts/patrimonioProvider';
import { ItensContext } from '../../../../contexts/ItensProvider';
import { UserContext } from '../../../../contexts/userProvider';

const defaultItem = {
    descricao: '',
    codigo: '',
    tipo: '',
    quantidade_contratada: '',
    quantidade_implantada: '',
    grupo: '',
    unidade: '',
    valor_unitario: ''
};

const EditarItens = ({ client, setClient, handleFecharClick }) => {
    const { grupos } = useContext(PatrimonioContext);
    const { setAlert } = useContext(UserContext);
    const { getItensByClient, itens, loading, setItens, deleteItens, updateItens } = useContext(ItensContext);
    const [itensToDelete, setItensToDelete] = useState([]);
    useEffect(() => {
        getItensByClient(client.id);
    }, []);

    useEffect(() => {
        let newClient = client;
        newClient.itens = itens || [];
        setClient({ ...newClient });
    }, [itens]);

    const handleItemChange = (index, field, value) => {
        const updatedItens = client.itens?.map((item, i) =>
            i === index ? { ...item, [field]: value } : item
        );
        setClient({ ...client, itens: updatedItens });
    };

    const addItem = () => {
        const newItem = defaultItem;
        const updatedItens = [...(client.itens || []), newItem];
        setClient({ ...client, itens: updatedItens });
    };

    const removeItem = (index) => {
        const updatedItens = client.itens?.filter((_, i) => i !== index);
        const itemToDelete = client.itens?.find((_, i) => i === index);
        if (itemToDelete.id) {
            setItensToDelete([...itensToDelete, itemToDelete.id]);
        }
        setClient({ ...client, itens: updatedItens });
    };

    const handleSaveitens = async () => {
        try {
            const [updatedItens, deleteResponse] = await Promise.all([
                updateItens(client.itens, client.id),
                itensToDelete.length > 0 ? deleteItens(itensToDelete) : Promise.resolve()
            ]);

            let updateSucces = updatedItens?.success;
            let deleteSucces = itensToDelete.length > 0 ? deleteResponse?.success : true;

            if (itensToDelete.length > 0 && !deleteSucces) {
                setAlert({ visible: true, title: "Atenção!", placeholder: deleteResponse.message, confirm: false, type: "warning" });
            }
            else if (!updateSucces && !deleteSucces) {
                setAlert({ visible: true, title: "Atenção!", placeholder: updatedItens.message, confirm: false, type: "warning" });
            }
            else if (!updateSucces) {
                setAlert({ visible: true, title: "Atenção!", placeholder: updatedItens.message, confirm: false, type: "warning" });
            }
            else if (updateSucces || deleteSucces) {
                setAlert({ visible: true, title: "Sucesso!", placeholder: updatedItens.message, confirm: false, type: "success" });
            }

            handleFecharClick();

        } catch (error) {
            console.error('Erro ao salvar itens:', error);
            setAlert({ visible: true, title: "Atenção!", placeholder: "Erro ao salvar itens", confirm: false, type: "warning" });

        }
    };


    if (loading) return <div>Carregando...</div>
    return (
        <div style={{ width: '100%' }}>
            {client?.itens?.map((item, index) => (
                <div
                    key={index}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                        width: '100%',
                    }}
                >
                    <div className="visualizar-modal-content-item-cadastro content-itens" style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%'
                    }}>
                        <h2
                            style={{
                                width: '100%',
                                textAlign: 'center',
                                marginBottom: '10px',
                            }}>Descrição:</h2>
                        <textarea
                            type="text"
                            value={item.descricao}
                            onChange={(e) =>
                                handleItemChange(index, 'descricao', e.target.value)
                            }
                            required
                            aria-label={`Descrição do item ${index + 1}`}
                        />
                    </div>
                    <div className="visualizar-modal-content-item-cadastro content-itens">
                        <h2>Código:</h2>
                        <input
                            type="text"
                            value={item.codigo}
                            onChange={(e) =>
                                handleItemChange(index, 'codigo', e.target.value)
                            }
                            aria-label={`Código do item ${index + 1}`}
                        />
                    </div>
                    <div className="visualizar-modal-content-item-cadastro content-itens">
                        <h2>Tipo:</h2>
                        <select
                            value={item.tipo}
                            onChange={(e) =>
                                handleItemChange(index, 'tipo', e.target.value)
                            }
                            required
                            aria-label={`Tipo do item ${index + 1}`}
                        >
                            <option value="">Selecione o tipo</option>
                            <option value="Equipamento">Equipamento</option>
                            <option value="Licença">Licença</option>
                            <option value="Serviço">Serviço</option>
                            <option value="Minutos">Minutos</option>
                            <option value="Valor">Valor</option>
                        </select>
                    </div>
                    <div className="visualizar-modal-content-item-cadastro content-itens">
                        <h2>Quantidade Contratada:</h2>
                        <input
                            type="text"
                            value={item.quantidade_contratada}
                            onChange={(e) =>
                                !isNaN(e.target.value) &&
                                handleItemChange(index, 'quantidade_contratada', e.target.value)
                            }
                            required
                            aria-label={`Quantidade Contratada do item ${index + 1}`}
                        />
                    </div>
                    <div className="visualizar-modal-content-item-cadastro content-itens">
                        <h2>Grupo:</h2>
                        <select
                            value={item.grupo}
                            onChange={(e) =>
                                handleItemChange(index, 'grupo', e.target.value)
                            }
                            required
                            aria-label={`Grupo do item ${index + 1}`}
                        >
                            <option value="">Selecione um grupo</option>
                            {grupos.map((grupo, i) => (
                                <option key={i} value={grupo.id}>
                                    {grupo.nome}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="visualizar-modal-content-item-cadastro content-itens">
                        <h2>Unidade:</h2>
                        <select
                            value={item.unidade}
                            onChange={(e) =>
                                handleItemChange(index, 'unidade', e.target.value)
                            }
                            required
                            aria-label={`Unidade do item ${index + 1}`}
                        >
                            <option value="">Selecione a unidade</option>
                            <option value="Mês">Mês</option>
                            <option value="Unitário">Unitário</option>
                            <option value="Minutos">Minutos</option>
                        </select>
                    </div>
                    <div className="visualizar-modal-content-item-cadastro content-itens">
                        <h2>Valor Unitário:</h2>
                        <input
                            type="text"
                            value={item.valor_unitario}
                            onChange={(e) =>
                                handleItemChange(index, 'valor_unitario', e.target.value)
                            }
                            required
                            aria-label={`Valor Unitário do item ${index + 1}`}
                        />
                    </div>
                    <button
                        type="button"
                        onClick={() => removeItem(index)}
                        className="remove-item-button"
                        style={{
                            backgroundColor: '#ff4d4d',
                            color: '#fff',
                            border: 'none',
                            padding: '10px 15px',
                            borderRadius: '5px',
                            cursor: 'pointer',
                            marginRight: '10px',
                            marginBottom: '80px',
                            marginTop: '50px',
                            width: '50%'
                        }}
                    >
                        Remover Item
                    </button>
                </div>
            ))}
            <button
                type="button"
                onClick={addItem}
                className="add-item-button"
                style={{
                    backgroundColor: '#4CAF50',
                    color: '#fff',
                    border: 'none',
                    padding: '10px 15px',
                    borderRadius: '5px',
                    cursor: 'pointer',
                    marginRight: '10px',
                    marginBottom: '80px',
                    width: '50%'
                }}
            >
                Adicionar Item
            </button>
            <div>
                <button
                    type="button"
                    onClick={handleSaveitens}
                    className="visualizar-modal-button"
                    style={{
                        border: 'none',
                        padding: '10px 15px',
                        borderRadius: '5px',
                        cursor: 'pointer',
                        marginRight: '10px',
                    }}
                >
                    Salvar Itens
                </button>
                <button
                    type="button"
                    onClick={handleFecharClick}
                    className="visualizar-modal-button"
                    style={{
                        backgroundColor: '#ff4d4d',
                        color: '#fff',
                        border: 'none',
                        padding: '10px 15px',
                        borderRadius: '5px',
                        cursor: 'pointer',
                        marginRight: '10px',
                    }}
                >
                    Fechar
                </button>
            </div>
        </div>
    );
};

export default EditarItens;