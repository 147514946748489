import React from 'react';

const LabelInput = ({ handleInputChange, dados }) => {
    const handleKeyPress = (e) => {
        if (dados.nextInput) {
            if (e.target.value.length >= dados.maxLength) {
                const nextInput = document.getElementById(dados.nextInput);
                if (nextInput) {
                    nextInput.focus();
                }
            }
        }
    };

    return (
        <>
            <label style={dados.visible ? { display: 'none' } : null}>{dados.titulo}</label>

            {
                dados?.disabled ?
                <p>
                    {dados.valor === 'Nenhum dado cadastrado.' ? '' : dados.valor}
                </p>
                :
                <input
                    defaultValue={dados.valor === 'Nenhum dado cadastrado.' ? '' : dados.valor}
                    name={dados.name}
                    onChange={(e) => handleInputChange(e, dados.index)}
                    onKeyUp={handleKeyPress}
                    disabled={dados.disabled ? dados.disabled : false}
                    placeholder={dados.placeholder ? dados.placeholder : ''}
                    style={dados.visible ? { display: 'none' } : null}
                    id={dados.titulo}
                />
            }
        </>
    );
};

export default LabelInput;
