import React from "react";

const SelectLabel = ({ dados, handleInputChange }) => {
  return (
    <>
      <label>{dados?.titulo}</label>
      {
        dados?.disabled ?
          <p>
            {dados?.valor === 'Nenhum dado cadastrado.' ? '' : dados?.valorSelect ? dados?.valorSelect : dados?.valor}
          </p>
          : <select
            name={dados?.name}
            onChange={(e) => handleInputChange(e, dados?.index, dados?.requiredValue)}
            value={dados?.valorSelect ? dados?.valorSelect : dados?.valor}
            disabled={dados?.disabled ? dados?.disabled : false}
            required={dados?.required ? dados?.required : false}

          >
            <option key={dados.titulo} value="">
              Selecione uma opção
            </option>
            {dados.options &&
              dados.options.map((item, index) => (
                <option key={index} value={dados.value === 'nome' ? item.name : (item.id || item._id)}>
                  {item.nome}
                </option>
              ))}
          </select>
      }
    </>
  );
};

export default SelectLabel;
