import { useState, useContext, useEffect } from 'react';
import { UserContext } from '../../contexts/userProvider';
import { PatrimonioContext } from '../../contexts/patrimonioProvider';
import { FornecedoresContext } from '../../contexts/fornecedoresProvider';
import DataTable from '../Table';
import LoadingSpinner from '../Loader/load';
import { cnpj } from 'cpf-cnpj-validator';
import ProdutoEdit from './Type/produtoEdit';
import './styles.css';

const Entrada = () => {
    const { user,setAlert } = useContext(UserContext);
    const { criarPatrimonioNfsByXml } = useContext(PatrimonioContext);
    const { allMarcas, allModelos, fornecedores } = useContext(FornecedoresContext);
    const [xmlData, setXmlData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [produtoEdit, setProdutoEdit] = useState(null)
    const [marcas, setMarcas] = useState([]);
    const [modelos, setModelos] = useState([]);

    useEffect(() => {
        receiveMarcasModelos();
    },[])

    const receiveMarcasModelos = async () => {
        let marcas = await allMarcas();
        let modelos = await allModelos();
        setMarcas(marcas);
        setModelos(modelos);
    }


    const handleFileChange = (event) => {
        const file = event?.target?.files[0];
        const fileType = file?.name.split('.').pop().toLowerCase();
        if (fileType !== 'xml') return setAlert({ visible: true, title: "Atenção!", 
            placeholder: 'Selecione um arquivo XML', confirm: false, type: "warning" });
        const reader = new FileReader();

        reader.onload = (event) => {
            setLoading(true);
            const xmlString = event.target.result;
            const parser = new DOMParser();
            const xmlDoc = parser.parseFromString(xmlString, 'text/xml');

            if (xmlDoc.documentElement.nodeName === 'parsererror') {
                setAlert({ visible: true, title: "Atenção!", placeholder: 'Erro ao carregar o XML', confirm: false, type: "warning" });
                console.error('Erro ao carregar o XML');
                return;
            }
            // Extrair informações do XML
            const nomeFornecedor = xmlDoc.querySelector('xNome').textContent;
            const cnpj = xmlDoc.querySelector('CNPJ').textContent;
            const endereco = xmlDoc.querySelector('xLgr').textContent;
            const numero = xmlDoc.querySelector('nro').textContent;
            const cidade = xmlDoc.querySelector('xMun').textContent;
            const uf = xmlDoc.querySelector('UF').textContent;
            const telefone = xmlDoc.querySelector('fone').textContent;
            const numeroNota = xmlDoc.querySelector('nNF').textContent;
            let dataEmissao = xmlDoc.querySelector('dhEmi').textContent
            const valorTotalNFE = xmlDoc.querySelector('vNF').textContent;

            // Processar produtos
            const produtos = [];
            const produtosXML = xmlDoc.querySelectorAll('det');
            produtosXML.forEach((produtoXML) => {
                let produto = produtoXML.querySelector('xProd').textContent;
                let quantidade = produtoXML.querySelector('qCom').textContent;
                let valorUnitario = produtoXML.querySelector('vUnCom').textContent;
                let valorTotal = produtoXML.querySelector('vProd').textContent;
                valorUnitario = `R$ ${parseFloat(valorUnitario).toFixed(2)}`;
                valorTotal = `R$ ${parseFloat(valorTotal).toFixed(2)}`;
                quantidade = parseFloat(quantidade).toFixed(0);
                let fornecedor = 2;
                let marca = marcas.find((marca) => {
                    if(produto.includes(marca.nome)){
                        return marca.id;
                    }
                });
                let modelo = modelos.find((modelo) => {
                    if(produto.includes(modelo.nome)){
                        return modelo.id;
                    }
                });

                if(!marca){
                    marca = marcas.find((marca) => marca.id === modelo?.id_marca);
                }

                produtos.push({
                    id: produtos.length + 1,
                    nome: produto,
                    quantidade,
                    valorUnitario,
                    valorTotal,
                    fornecedor,
                    marca: marca?.id,
                    modelo: modelo?.id
                });
            });
            const data = new Date(dataEmissao);
            const dia = data.getDate().toString().padStart(2, '0');
            const mes = (data.getMonth() + 1).toString().padStart(2, '0');
            const ano = data.getFullYear();
            dataEmissao = `${dia}/${mes}/${ano}`;
            setXmlData({ nomeFornecedor, cnpj, endereco, numero, cidade, uf, telefone, produtos, numeroNota, dataEmissao, valorTotalNFE, user });
            setLoading(false);
        };

        reader.readAsText(file);
    };

    const visualizar = (id) => {
        let produto = xmlData.produtos.find((produto) => produto.id === id);
        setProdutoEdit(produto);
    }

    const salvarProduto = (produto) => {
        if(!produto.marca || !produto.modelo){
            setAlert({ visible: true, title: "Atenção!", placeholder: 'Selecione a marca e modelo do produto', confirm: false, type: "warning" });
            return;
        }
        let produtos = xmlData.produtos.map((item) => {
            if (item.id === produto.id) {
                return produto;
            }
            return item;
        });
        setXmlData({ ...xmlData, produtos });
        setProdutoEdit(null);
    }

    const headers = [
        { label: "Produto", key: "nome", sortable: true },
        { label: "Quantidade", key: "quantidade", sortable: false },
        { label: "Valor Unitário", key: "valorUnitario", sortable: false },
        { label: "Valor Total", key: "valorTotal", sortable: false },
        { label: "Ações", key: "actions", sortable: false, actions: { "adicionar": visualizar }}
    ]

    const handleCriarPatrimonio = () => {
        let newData = { ...xmlData }
        
        if(xmlData.produtos.length === 0){
            setAlert({ visible: true, title: "Atenção!", placeholder: 'Adicione produtos a nota fiscal', confirm: false, type: "warning" });
            return;
        }

        for(let i = 0; i < newData.produtos.length; i++){
            if(!newData.produtos[i].marca || !newData.produtos[i].modelo){
                setAlert({ visible: true, title: "Atenção!", placeholder: 'Selecione a marca e modelo de todos os produtos', confirm: false, type: "warning" });
                return;
            }
        }

        setAlert({
            type: 'confirm',
            placeholder: 'Deseja realmente adicionar os produtos ao estoque?',
            visible: true,
            confirm: true,
            title: 'Atenção!',
            callback: () => criarPatrimonioNfsByXml(newData),
            params: newData
        })
        setXmlData(null);
    }

    const closeEditModal = () => {
        setProdutoEdit(null);
    }

    return (
        <div className='entrada-contander'>
            <div className='entrada-header'>
                <h1>Entrada de Nota Fiscal - XML</h1>
            </div>
            <div className='entrada-body'>
                <div className='input-box'>
                    {xmlData ? (<button className='entrada-limpar-button' onClick={() => setXmlData(null)}>Remover Nota</button>) : (
                        <input type="file" accept=".xml" onChange={handleFileChange} className='entrada-button' />
                    )}
                </div>
                {xmlData && (
                    <>
                        <div className='nota-info-box'>
                            <div className='nota-info-header'>
                                <p>Nome do fornecedor:</p> <p> {xmlData.nomeFornecedor}</p>
                                <p>CNPJ: {cnpj.format(xmlData.cnpj)}</p>
                                <p>Endereço: {xmlData.endereco}, {xmlData.numero}, {xmlData.cidade}, {xmlData.uf}</p><br />
                                <p>Telefone: {xmlData.telefone}</p>
                                <p>Número da Nota: {xmlData.numeroNota}</p>
                                <p>Data de Emissão: {xmlData.dataEmissao}</p>
                                <p>Valor Total da Nota: R$ {parseFloat(xmlData.valorTotalNFE).toFixed(2)}</p>
                            </div>
                            <h2>Produtos:</h2>
                            {loading ? <LoadingSpinner /> : <DataTable headers={headers} data={xmlData.produtos} itemsPerPage={5} />}
                        </div>
                        <button className='entrada-limpar-button' style={{ marginBottom: "20px" }} onClick={handleCriarPatrimonio}>Confirmar entrada de estoque</button>
                    </>
                )}

                {produtoEdit && (
                    <ProdutoEdit produto={produtoEdit} salvarProduto={salvarProduto} closeEditModal={closeEditModal} marcas={marcas} modelos={modelos} />
                )}
            </div>
        </div>
    );
};

export default Entrada;
