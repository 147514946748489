import React, { useContext, useEffect, useState } from 'react';
import "./styles.css";
import { ClientContext } from '../../../contexts/clientProvider';
import DetailsModal from './Details';

const Moviments = ({ closeModal, month, ano, data }) => {
    const [movedClients, setMovedClients] = useState([]);
    const [showDetails, setShowDetails] = useState('');
    const { clientes, getPatsDetails } = useContext(ClientContext);


    useEffect(() => {
        adjustAndGetData();
    }, [data]);

    const adjustAndGetData = async () => {
        let patsNFS = data.map((item) => item.id_patrimonioNFS).filter(i => i !== null);
        let pats = data.map((item) => item.id_patrimonio).filter(i => i !== null);

        let detailedPats = await getPatsDetails(pats, patsNFS);

        let movesDetails = [...data]?.map((item) => {
            let detailedPat = detailedPats.find((pat) => pat.patrimonio_id === item.id_patrimonio || pat.patrimonio_id === item.id_patrimonioNFS);
            if (detailedPat) {
                item.ramal = detailedPat.ramal;
                item.marca = detailedPat.marca;
                item.modelo = detailedPat.modelo;
                item.etiqueta = detailedPat.nEtiqueta;
                item.nome_anexo_anterior = item.movimento_anterior?.nome_anexo_anterior;
            }
            return item;
        })
        const clientsChanges = [...clientes].map((client) => {
            let clientsMoves = movesDetails.filter((item) => item.id_cliente === client.id);
            return {
                ...client,
                moves: clientsMoves
            }
        });
        const clientsFiltered = clientsChanges.filter((client) => client.moves.length > 0);
        const clientsSorted = clientsFiltered.sort((a, b) => a.nome.localeCompare(b.nome));
        // console.log(clientsSorted);
        setMovedClients(clientsSorted);
    }



    const hasZero = (month) => month < 10 ? "0" + month : month;




    return (
        <div className="overlay" onClick={e => (e.target === e.currentTarget) ? closeModal() : null}>
            {showDetails && (<DetailsModal
                client={movedClients.find(client => client.id === showDetails)}
                closeDetailsModal={() => setShowDetails('')}
                month={month}
                ano={ano}
                />
            
            )}
            <div className="modal">
                <div className="header">
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: "50px" }}>
                        <h2>
                            <strong>Data:</strong> {hasZero(month)}/{ano}
                        </h2>
                    </div>
                    <button className="closeButton" onClick={closeModal} style={{ marginLeft: "auto", fontSize: "45px" }}>
                        &times;
                    </button>
                </div>
                <div className="body">
                    <div>
                        <h2>Clientes Movimentados</h2>
                        <div className="moved-clients-container">
                            {
                                movedClients.length > 0 ? movedClients.map((client) => (
                                    <div key={client.id} className="client-moved" onClick={() => setShowDetails(client.id)}>
                                        {client.nome}
                                    </div>
                                )) : (
                                    <h3>
                                        <strong>Nenhum cliente movido</strong>
                                    </h3>
                                )
                            }
                        </div>
                    </div>
                    <h2>Todos Detalhes</h2>
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                        {
                            data.map((item) => (
                                <div
                                    key={item.id}
                                    className='movimentacao-item'
                                >
                                    <h2><strong>Patrimonio:</strong> {item.nEtiqueta}</h2>
                                    <p><strong>Usuário:</strong> {item.usuario}</p>
                                    <p><strong>Anexo Atual:</strong> {item.nome_anexo_atual}</p>
                                    {item.movimento_anterior && (
                                        <div>
                                            <h3>Movimento Anterior:</h3>
                                            <p>ID: {item.movimento_anterior.id}</p>
                                            <p>Usuário: {item.movimento_anterior.usuario}</p>
                                            <p>Anexo Anterior: {item.movimento_anterior.nome_anexo_anterior}</p>
                                        </div>
                                    )}
                                </div>
                            ))}
                    </div>
                </div>
                <div className="footer">
                    <button className="button" onClick={closeModal}>
                        Fechar
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Moviments;
